import React, { useEffect, useState } from 'react';
import { useNavigate } from '@reach/router';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { useMessageSource } from 'react-message-source';

import {
  createAppointment,
  deleteAppointmentById,
  getAppointmentById,
  updateAppointment,
} from '../../../services/appointmentsService';
import { getUsers } from '../../../services/usersService';
import Input from '../../../components/final-form/Input';
import Select from '../../../components/final-form/Select';
import Modal from '../../../components/Modal';
import { toLocalDateFormat, toLocalTimeFormat } from '../../../components/final-form/utils';
import { RemoveIcon } from '../../../components/Icons';
import { checkForValidationErrors } from '../../../utils/errors';
import { useCurrentPrincipal } from '../../../security/AuthenticationContext';

const AppointmentDetails = ({ id }) => {
  const principal = useCurrentPrincipal();

  const now = new Date();
  const initialState = {
    patients: [''],
    scheduledFor: now,
    durationInMinutes: 45,
  };

  if (principal.role === 'doctor') {
    initialState.doctor = principal._id;
  }

  const [appointment, setAppointment] = useState(initialState);

  const [doctors, setDoctors] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const navigate = useNavigate();
  const { getMessage } = useMessageSource('client.appointment.details');

  useEffect(() => {
    if (principal.role === 'admin') {
      getUsers('role=doctor').then((doctors) => {
        setDoctors(doctors);
      });
    } else {
      setDoctors([
        {
          _id: principal._id,
          name: principal.name,
        },
      ]);
    }

    if (id !== 'new') {
      getAppointmentById(id).then((appointment) => {
        setAppointment(appointment);
      });
    }
  }, [id, principal._id, principal.role, principal.name]);

  const handleSubmit = async (values) => {
    const { _id, ...data } = values;

    if (data.scheduledForDate && data.scheduledForTime) {
      const dateParts = data.scheduledForDate.split('-');
      const timeParts = data.scheduledForTime.split(':');
      const scheduledFor = new Date(dateParts[0], dateParts[1] - 1, dateParts[2], timeParts[0], timeParts[1], 0, 0);
      data.scheduledFor = scheduledFor.toISOString();
    }

    try {
      if (_id) {
        const appointment = await updateAppointment(_id, data);
        const { doctor, ...restAppointment } = appointment;
        setAppointment({
          ...restAppointment,
          doctor: doctor._id,
        });
      } else {
        await createAppointment(data);
        navigate('./');
      }
    } catch (error) {
      const validationErrors = checkForValidationErrors(error, getMessage);
      if (validationErrors && validationErrors.scheduledFor) {
        validationErrors.scheduledForDate = validationErrors.scheduledFor;
      }
      return validationErrors;
    }
  };

  const handleDelete = () => {
    setShowDeleteConfirmation(true);
  };

  const handleAccept = () => {
    setDeleting(true);
    deleteAppointmentById(id)
      .then(() => {
        navigate('./');
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  const scheduledFor = new Date(appointment.scheduledFor);
  appointment.scheduledForDate = toLocalDateFormat(scheduledFor);
  appointment.scheduledForTime = toLocalTimeFormat(scheduledFor);
  const initialValues = {
    ...appointment,
    scheduledForDate: toLocalDateFormat(scheduledFor),
    scheduledForTime: toLocalTimeFormat(scheduledFor),
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        mutators={{
          ...arrayMutators,
        }}
        render={({
          handleSubmit,
          submitting,
          form: {
            mutators: { push },
          },
        }) => (
          <form onSubmit={handleSubmit}>
            {id !== 'new' && (
              <Field
                name="_id"
                component={Input}
                type="text"
                placeholder={getMessage('id.placeholder')}
                labelText={getMessage('id')}
                readOnly
              />
            )}
            <Field name="doctor" component={Select} type="select" labelText={getMessage('doctor')}>
              <option>{getMessage('doctor.placeholder')}</option>
              {doctors.map((doctor) => (
                <option key={doctor._id} value={doctor._id}>
                  {doctor.name}
                </option>
              ))}
            </Field>
            <span className="text-gray-700">{getMessage('patients')}</span>
            <FieldArray name="patients">
              {({ fields }) => (
                <>
                  {fields.map((name, index) => (
                    <div key={name} className="flex items-center">
                      <label className="block w-24">
                        {getMessage('patient')} #{index + 1}
                      </label>
                      <div className="w-1/2">
                        <Field
                          type="text"
                          name={`${name}`}
                          component={Input}
                          placeholder={getMessage('email.placeholder')}
                        />
                      </div>
                      {index !== 0 && (
                        <button type="button" className="inline-block" onClick={() => fields.remove(index)}>
                          <RemoveIcon />
                        </button>
                      )}
                    </div>
                  ))}
                  <button
                    type="button"
                    disabled={fields.length > 1}
                    className="inline-flex items-center justify-center px-4 py-1 my-1 mr-1 space-x-1 bg-gray-200 rounded-md shadow hover:bg-opacity-20 disabled:opacity-50"
                    onClick={() => push('patients', undefined)}
                  >
                    {getMessage('patient.add')}
                  </button>
                </>
              )}
            </FieldArray>
            <div className="flex">
              <Field
                name="scheduledForDate"
                type="date"
                component={Input}
                placeholder="yyyy-mm-dd"
                labelText={getMessage('scheduledfor')}
              />
              <Field
                name="scheduledForTime"
                type="time"
                step="300"
                component={Input}
                placeholder="HH:mm"
                labelText={getMessage('scheduledfortime')}
              />
            </div>
            <Field
              name="durationInMinutes"
              component={Input}
              type="number"
              step={5}
              placeholder={getMessage('duration.placeholder')}
              labelText={getMessage('duration')}
            />
            <div className="flex pt-1 items-center">
              <button
                disabled={submitting}
                className="inline-flex items-center justify-center px-4 py-1 space-x-1 bg-gray-200 rounded-md shadow hover:bg-opacity-20 mr-1"
                type="submit"
              >
                {getMessage('submit')}
              </button>
              {id !== 'new' && (
                <button
                  onClick={handleDelete}
                  className="inline-flex items-center justify-center px-4 py-1 space-x-1 bg-red-500 text-white rounded-md shadow hover:bg-red-600"
                  type="button"
                >
                  {getMessage('delete')}
                </button>
              )}
              {submitting && <div className="animate-spin rounded-full border-t-2 border-blue-200 h-4 w-4 m-2" />}
            </div>
          </form>
        )}
      />
      <Modal
        show={showDeleteConfirmation}
        title={getMessage('modal.title')}
        text={getMessage('modal.text')}
        acceptText={getMessage('modal.yes')}
        cancelText={getMessage('modal.no')}
        onAccept={handleAccept}
        onCancel={() => {
          setShowDeleteConfirmation(false);
        }}
        disableAccept={deleting}
      />
    </>
  );
};

export default AppointmentDetails;
