import React, { useCallback, useState } from 'react';
import { useMessageSource } from 'react-message-source';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { AddIcon } from '../components/Icons';
import Modal from '../components/Modal';
import { createPost, deletePostById, getPosts } from '../services/postsService';
import Post from '../components/post/Post';

const Posts = ({ onLogout }) => {
  const { getMessage } = useMessageSource('client.client.posts');
  const [progress, setProgress] = React.useState(0);
  const observerElement = React.useRef(null);

  const queryClient = useQueryClient();
  const { isLoading, error, data: postsData, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery(
    'posts',
    ({ pageParam = 1 }) => getPosts(`page=${pageParam}&limit=5`),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.pagination.next && lastPage.pagination.next.page;
      },
    }
  );
  const addPostMutation = useMutation(
    (newPost) => {
      return createPost(newPost, setProgress);
    },
    {
      onSuccess: () => queryClient.invalidateQueries('posts'),
    }
  );
  const deletePostMutation = useMutation(
    (postId) => {
      return deletePostById(postId);
    },
    {
      onSuccess: () => queryClient.invalidateQueries('posts'),
    }
  );

  const [newPostContent, setNewPostContent] = React.useState('');
  const [newPostImage, setNewPostImage] = React.useState();
  const [preview, setPreview] = useState();

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deletingId, setDeletingId] = useState('');
  const [deleting, setDeleting] = useState(false);

  // create a preview as a side effect, whenever selected file is changed
  React.useEffect(() => {
    if (!newPostImage) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(newPostImage);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [newPostImage]);

  const handleObserver = useCallback(
    (entries) => {
      const [target] = entries;
      if (target.isIntersecting && hasNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage]
  );

  React.useEffect(() => {
    if (observerElement.current) {
      const element = observerElement.current;
      const option = { threshold: 0 };
      const observer = new IntersectionObserver(handleObserver, option);
      observer.observe(element);
      return () => observer.unobserve(element);
    }
  }, [fetchNextPage, hasNextPage, handleObserver]);

  const addNewPost = React.useCallback(() => {
    addPostMutation
      .mutateAsync({
        content: newPostContent,
        file: newPostImage,
      })
      .then(() => {
        setNewPostImage(undefined);
        setNewPostContent('');
        setProgress(0);
      });
  }, [addPostMutation, newPostContent, newPostImage]);

  const handleOnDeleteClick = useCallback((postId) => {
    setDeletingId(postId);
    setShowDeleteConfirmation(true);
  }, []);

  const handleAcceptDelete = useCallback(() => {
    setDeleting(true);
    deletePostMutation.mutateAsync(deletingId).finally(() => {
      setDeleting(false);
      setShowDeleteConfirmation(false);
    });
  }, [deletingId, deletePostMutation]);

  if (isLoading) return getMessage('loading');

  if (error) return 'An error has occurred: ' + error.message;

  return (
    <div className="flex-1 mx-auto lg:w-1/2 w-full">
      <div className="flex">
        <h2 className="flex-grow text-xl font-semibold">{getMessage('title')}</h2>
        <button className="background-transparent text-xs outline-none focus:outline-none" onClick={onLogout}>
          {getMessage('client.button.logout')}
        </button>
      </div>
      <div className="bg-white rounded-lg shadow-xl w-full">
        <div className="m-4">
          <div className="flex items-center justify-center w-full">
            <label className="flex flex-col w-full h-64 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
              <div className="relative flex flex-col items-center justify-center pt-7">
                {newPostImage && (
                  <img
                    id="preview"
                    src={preview}
                    className="absolute object-cover inset-0 w-full h-64"
                    alt=""
                  />
                )}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                    clipRule="evenodd"
                  />
                </svg>
                <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                  {getMessage('select.photo')}
                </p>
              </div>
              <input
                className="hidden"
                type="file"
                accept="image/*"
                onChange={(event) => setNewPostImage(event.target.files[0])}
              />
            </label>
          </div>
          {progress > 0 && progress < 100 && (
            <>
              <p>{getMessage('uploading')}</p>
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                <div
                  className="bg-gray-600 h-2.5 rounded-full dark:bg-gray-300"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </>
          )}
        </div>
        <div className="flex items-center justify-center w-full">
          <textarea
            className="w-full m-4 resize-none"
            rows={3}
            onChange={(event) => setNewPostContent(event.target.value)}
            value={newPostContent}
          />
        </div>
        <div className="flex flex-col sm:flex-row justify-end">
          <button
            disabled={!newPostContent && !newPostImage}
            type="button"
            className="inline-flex items-center justify-center px-4 py-1 my-1 mr-1 space-x-1 bg-gray-200 rounded-md shadow hover:bg-opacity-20 disabled:opacity-50"
            onClick={addNewPost}
          >
            <AddIcon />
            <span className="ml-2">{getMessage('button.add.text')}</span>
          </button>
        </div>
      </div>
      <h3 className="text-lg text-center my-2">{getMessage('title.all')}</h3>
      <div>
        {postsData.pages.map((group, i) => (
          <React.Fragment key={i}>
            {group.data.map((post) => (
              <Post
                key={post._id}
                editable={true}
                canAddComments={false}
                post={post}
                onPostDelete={handleOnDeleteClick}
              />
            ))}
          </React.Fragment>
        ))}
        <div className="flex flex-col items-center justify-center" ref={observerElement}>
          {hasNextPage && isFetchingNextPage && (
            <div className="animate-spin rounded-full border-t-4 border-blue-200 h-12 w-12 mb-4" />
          )}
        </div>
      </div>
      <Modal
        show={showDeleteConfirmation}
        title={getMessage('modal.title')}
        text={getMessage('modal.text')}
        acceptText={getMessage('modal.yes')}
        cancelText={getMessage('modal.no')}
        onAccept={handleAcceptDelete}
        onCancel={() => {
          setShowDeleteConfirmation(false);
        }}
        disableAccept={deleting}
      />
    </div>
  );
};

export default Posts;
