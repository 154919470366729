import { format } from 'date-fns';
import {
  AudioMutedIcon,
  AudioOnIcon,
  ChatIcon,
  CloseVideoCallIcon,
  EnterFullscreenIcon,
  MirrorOffIcon,
  MirrorOnIcon,
  VideoMutedIcon,
  VideoOnIcon
} from '../Icons';

const ToolButton = ({ children, ...rest }) => (
  (
    <button
      {...rest}
      className="text-gray-500 bg-transparent border border-solid border-gray-500 hover:bg-gray-500 hover:text-white active:bg-gray-600 font-bold uppercase px-2 py-2 rounded-full outline-none focus:outline-none m-1"
      type="button" style={{ transition: 'all .15s ease' }}>
      {children}
    </button>
  )
);

const ToolBar = ({
  audioOn,
  videoOn,
  onAudioClick,
  onVideoClick,
  onChatClick,
  onDisconnectClick,
  mirrorToggle,
  onMirrorToggleClick,
  serverTime,
  newMessage,
  onImagePreviewClick
}) => {

  return (
    <section className="block fixed inset-x-0 bottom-0 z-30 shadow" style={{ backgroundColor: '#272727' }}>
      <div className="flex justify-between items-center">
        <span className="clock text-center text-2xl tracking-wider text-gray-300 p-1">
          {format(serverTime, 'HH:mm')}
        </span>
        <div className='flex justify-between'>
          <ToolButton onClick={onVideoClick}>
            {
              videoOn
                ? <VideoOnIcon className='inline-block mb-1'/>
                : <VideoMutedIcon className='inline-block mb-1'/>
            }
          </ToolButton>
          <ToolButton onClick={onDisconnectClick}>
            <CloseVideoCallIcon className='inline-block mb-1'/>
          </ToolButton>
          <ToolButton onClick={onAudioClick}>
            {
              audioOn
                ? <AudioOnIcon className='inline-block mb-1'/>
                : <AudioMutedIcon className='inline-block mb-1'/>
            }
          </ToolButton>
          <ToolButton onClick={onMirrorToggleClick}>
            {mirrorToggle ? <MirrorOnIcon/> : <MirrorOffIcon/>}
          </ToolButton>
          {/*<ToolButton onClick={onImagePreviewClick}>*/}
          {/*  <EnterFullscreenIcon className='inline-block mb-1'/>*/}
          {/*</ToolButton>*/}
        </div>
        <ToolButton onClick={onChatClick}>
          <ChatIcon className='inline-block mb-1'/>
          {
            newMessage && (
              <>
                <div className="absolute right-1 p-1 bg-red-400 rounded-full top-2 animate-ping"/>
                <div className="absolute right-1 p-1 bg-red-400 border border-white rounded-full top-2" />
              </>
            )
          }
        </ToolButton>
      </div>
    </section>
  );
};

export default ToolBar;