import React, { useEffect, useState } from 'react';
import { createClient, deleteClientById, getClientById, updateClient } from '../../../services/clientsService';
import { useMessageSource } from 'react-message-source';
import { Link, useNavigate } from '@reach/router';
import { Field, Form } from 'react-final-form';
import Input from '../../../components/final-form/Input';
import Modal from '../../../components/Modal';

const ClientDetails = ({ id }) => {
  const [client, setClient] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const navigate = useNavigate();
  const { getMessage } = useMessageSource('client.client.details');

  useEffect(() => {
    if (id !== 'new') {
      getClientById(id).then((client) => {
        setClient(client);
      });
    } else {
      setClient({});
    }
  }, [id]);

  const handleSubmit = (values) => {
    const { _id, ...data } = values;
    if (_id) {
      updateClient(_id, data).then((client) => setClient(client));
    } else {
      createClient(data).then((client) => setClient(client));
    }
  };

  const handleDelete = () => {
    setShowDeleteConfirmation(true);
  };

  const handleAccept = () => {
    setDeleting(true);
    deleteClientById(id)
      .then(() => {
        navigate('./');
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={client}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="_id"
              component={Input}
              type="text"
              placeholder={getMessage('id.placeholder')}
              labelText={getMessage('id')}
              readOnly
            />
            <Field
              name="email"
              type="text"
              component={Input}
              placeholder={getMessage('email.placeholder')}
              labelText={getMessage('email')}
            />
            <Field
              name="name"
              type="text"
              component={Input}
              placeholder={getMessage('name.placeholder')}
              labelText={getMessage('name')}
            />
            <Field
              name="nickname"
              type="text"
              component={Input}
              placeholder={getMessage('nickname.placeholder')}
              labelText={getMessage('nickname')}
            />
            <div className="flex pt-1 items-center">
              <button
                disabled={submitting}
                className="inline-flex items-center justify-center px-4 py-1 space-x-1 bg-gray-200 rounded-md shadow hover:bg-opacity-20 mr-1"
                type="submit"
              >
                {getMessage('submit')}
              </button>
              {id !== 'new' && (
                <button
                  onClick={handleDelete}
                  className="inline-flex items-center justify-center px-4 py-1 space-x-1 bg-red-500 text-white rounded-md shadow hover:bg-red-600 mr-1"
                  type="button"
                >
                  {getMessage('delete')}
                </button>
              )}
              <Link to="../">
                <button className="inline-flex items-center justify-center px-4 py-1 space-x-1 bg-gray-200 rounded-md shadow hover:bg-opacity-20">
                  {getMessage('back')}
                </button>
              </Link>
              {submitting && <div className="animate-spin rounded-full border-t-2 border-blue-200 h-4 w-4 m-2" />}
            </div>
          </form>
        )}
      />
      <Modal
        show={showDeleteConfirmation}
        title={getMessage('modal.title')}
        text={getMessage('modal.text')}
        acceptText={getMessage('modal.yes')}
        cancelText={getMessage('modal.no')}
        onAccept={handleAccept}
        onCancel={() => {
          setShowDeleteConfirmation(false);
        }}
        disableAccept={deleting}
      />
    </>
  );
};

export default ClientDetails;
