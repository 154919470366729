import Forbidden from '../pages/Forbidden';
import { useCurrentPrincipal } from './AuthenticationContext';
import { Redirect } from '@reach/router';

const canPrincipalAccessRoute = (principal, rolesAllowed) => {
  return rolesAllowed.includes(principal.role);
};

const PrivateRoute = ({ Component, rolesAllowed, ...rest }) => {
  const principal = useCurrentPrincipal();

  if (!principal) {
    return <Redirect to="/login" noThrow/>;
  } else if (canPrincipalAccessRoute(principal, rolesAllowed)) {
    return <Component {...rest} />;
  }
  return <Forbidden/>;
};

export default PrivateRoute;
