import { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const ImagesPreview = ({ images, isOpen, onClose, activePhotoIndex = 0 }) => {
  const [photoIndex, setPhotoIndex] = useState(0);

  useEffect(() => {
    setPhotoIndex(activePhotoIndex);
  }, [activePhotoIndex]);

  if (!isOpen || !images.length) {
    return null;
  }

  return (
    <Lightbox
      mainSrc={images[photoIndex].src}
      nextSrc={images[(photoIndex + 1) % images.length].src}
      prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
      onCloseRequest={onClose}
      onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
      onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
    />
  );
};

export default ImagesPreview;
