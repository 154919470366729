import { useMessageSource } from 'react-message-source';

const Footer = () => {
  const { getMessage } = useMessageSource();
  const year = new Date().getFullYear();
  return (
    <footer className="flex items-center justify-between flex-shrink-0 p-4 border-t max-h-14">
      <div>{getMessage('client.app.name')} &copy; {year}</div>
    </footer>
  )
}

export default Footer;