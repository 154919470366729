import classnames from 'classnames';
import { useMessageSource } from 'react-message-source';
import { CloseIcon } from '../Icons';

const SidebarHeader = ({ sidebarOpen = false, toggleSidebarMenu }) => {
  const { getMessage } = useMessageSource('client.sidebarheader');
  const mobileLetter = getMessage('letter')
  const restOfName = getMessage('rest')
  const classes = classnames('flex items-center justify-between flex-shrink-0 p-2', {
    'lg:justify-center': !sidebarOpen
  });
  return (
    <div className={classes}>
      <span className='p-2 text-xl font-semibold leading-8 tracking-wider uppercase whitespace-nowrap'>
        {mobileLetter}<span className={classnames({ 'lg:hidden': !sidebarOpen })}>{restOfName}</span>
      </span>
      <button onClick={toggleSidebarMenu} className='p-2 rounded-md lg:hidden'>
        <CloseIcon />
      </button>
    </div>
  );
};

export default SidebarHeader;