export const SIGNAL_SERVER_URL = process.env.REACT_APP_SIGNAL_URL;
export const PEERJS_HOST = process.env.REACT_APP_PEERJS_HOST;
export const PEERJS_PORT = process.env.REACT_APP_PEERJS_PORT;

export const ICE_CONFIG = {
  iceTransportPolicy: 'all',
  reconnectTimer: 3000,

  iceServers: [
    {
      urls: process.env.REACT_APP_STUN_URL
    },
    {
      urls: process.env.REACT_APP_TURN_URL,
      username: process.env.REACT_APP_TURN_USER,
      credential: process.env.REACT_APP_TURN_PASSWORD
    }
  ]
};

export const DEBUG = process.env.NODE_ENV !== 'production';
export const PRODUCTION = !DEBUG;

export const RELEASE = process.env.REACT_APP_RELEASE;
