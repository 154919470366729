import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { FetchingProvider as FetchingMessageSourceProvider } from 'react-message-source';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const search = window.location.search;
const params = new URLSearchParams(search);
const lang = params.get('lang') || 'mk';

// Global site tag (gtag.js) - Google Analytics
if (process.env.NODE_ENV === 'production') {
  const s = document.createElement('script');
  s.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-F0EBL9NDBH');
  s.async = true;
  document.head.appendChild(s);

  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    window.dataLayer.push(arguments);
  };

  window.gtag('js', new Date());
  window.gtag('config', 'G-F0EBL9NDBH', {
    send_page_view: false
  });
}

ReactDOM.render(
  <React.StrictMode>
    <FetchingMessageSourceProvider url={`/api/v1/resources?lang=${lang}`}>
      <App/>
    </FetchingMessageSourceProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// Error handling
window.onerror = (msg, url, line, col, error) => {
  // Note that col & error are new to the HTML 5 spec and may not be
  // supported in every browser.  It worked for me in Chrome.
  let extra = !col ? '' : '\ncolumn: ' + col;
  extra += !error ? '' : '\nerror: ' + error;

  // You can view the information in an alert to see things working like this:
  const errorData = 'Error: ' + msg + '\nurl: ' + url + '\nline: ' + line + extra;
  console.error(errorData);

  if (process.env.NODE_ENV === 'production') {
    try {
      window.gtag('event', 'exception', {
        'description': errorData,
        'fatal': true
      });
    } catch (err) {
      console.error('Unable to track exception with GA', err);
    }
  }

  // If you return true, then error alerts (like in older versions of
  // Internet Explorer) will be suppressed.
  return true;
};

window.onunhandledrejection = (e) => {
  console.error(e);
  throw new Error(e.reason.stack);
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: registration => {
    alert('New version available!  Ready to update?');
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    window.location.reload();
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
