const ten = function(i) {
  return (i < 10 ? '0' : '') + i;
};

export const toLocalDateFormat = (value) => {
  if (!value) return '';
  const date = new Date(value),
    YYYY = date.getFullYear(),
    MM = ten(date.getMonth() + 1),
    DD = ten(date.getDate());

  return YYYY + '-' + MM + '-' + DD;
};

export const toLocalTimeFormat = (value) => {
  if (!value) return '';
  const date = new Date(value),
    HH = ten(date.getHours()),
    II = ten(date.getMinutes());

  return HH + ':' + II;
};


export const toLocalDateTimeFormat = (value) => {
  if (!value) return '';
  const date = new Date(value),
    YYYY = date.getFullYear(),
    MM = ten(date.getMonth() + 1),
    DD = ten(date.getDate()),
    HH = ten(date.getHours()),
    II = ten(date.getMinutes()),
    SS = ten(date.getSeconds());

  return YYYY + '-' + MM + '-' + DD + 'T' +
    HH + ':' + II + ':' + SS;
};