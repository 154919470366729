import { useCallback, useEffect, useState } from 'react';
import { useMessageSource } from 'react-message-source';
import { useNavigate } from '@reach/router';
import classnames from 'classnames';
import logo from '../assets/images/logo.png';
import { resetPassword } from '../services/usersService';

const ResetPassword = ({ token }) => {
  const { getMessage } = useMessageSource('client.reset.password');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsDifferent, setPasswordsDifferent] = useState(false);
  const [posting, setPosting] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handlePasswordsChange = useCallback((event) => {
    const { name, value } = event.target;
    if (name === 'password') {
      setPassword(value);
    } else {
      setConfirmPassword(value);
    }
  }, []);

  useEffect(() => {
    setPasswordsDifferent(password !== confirmPassword);
  }, [password, confirmPassword]);

  const handleResetPasswordClick = useCallback(
    (event) => {
      event.preventDefault();
      setPosting(true);
      resetPassword(token, { password })
        .then(() => {
          setMessage('success');
          setTimeout(() => navigate('/'), 2000);
        })
        .finally(() => setPosting(false));
    },
    [password, token, navigate]
  );

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 mx-auto">
      <div className="max-w-md w-full space-y-8">
        {message && <div className="bg-green-500 relative text-white py-3 px-3 rounded-lg">{getMessage(message)}</div>}
        <div>
          <img className="mx-auto h-24 w-auto" src={logo} alt="logo" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{getMessage('prompt')}</h2>
        </div>
        <form className="mt-8 space-y-6" action="#" method="POST">
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="password" className="sr-only">
                {getMessage('password')}
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm"
                placeholder={getMessage('client.user.details.password.placeholder')}
                onChange={handlePasswordsChange}
              />
            </div>
            <div>
              <label htmlFor="confirmPassword" className="sr-only">
                {getMessage('password')}
              </label>
              <input
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                autoComplete="current-password"
                required
                className={classnames(
                  'appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm',
                  {
                    'bg-red-400': passwordsDifferent,
                  }
                )}
                placeholder={getMessage('confirm.placeholder')}
                onChange={handlePasswordsChange}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
              onClick={handleResetPasswordClick}
              disabled={password !== confirmPassword || posting}
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <svg
                  className="h-5 w-5 text-red-500 group-hover:text-red-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
              {getMessage('button')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
