import React, { useEffect } from 'react';
import { globalHistory, Redirect, Router } from '@reach/router';
import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import Room from '../pages/Room';
import PrivateRoute from '../security/PrivateRoute';
import Administration from '../pages/administration/Administration';
import NotFound from '../pages/NotFound';
import { useCurrentPrincipal } from '../security/AuthenticationContext';
import { trackPageView } from '../utils/helper';
import PasswordlessLogin from '../pages/PasswordlessLogin';

const GoogleAnalyticsRouter = () => {

  const currentPrincipal = useCurrentPrincipal();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      trackPageView(window.location.pathname + window.location.search);
      return globalHistory.listen(({ location }) => {
        trackPageView(location.pathname + location.search);
      });
    }
  }, [currentPrincipal]);

  return (
    <Router className="flex h-screen overflow-y-hidden bg-white">
      <Redirect from="/" to="administration" noThrow/>
      <Login path="/login"/>
      <ForgotPassword path="/forgot-password"/>
      <ResetPassword path="/reset-password/:token"/>
      <Room path="/room/:roomId"/>
      <PasswordlessLogin path="/posts"/>
      <PrivateRoute Component={Administration} rolesAllowed={['admin', 'doctor']} path="/administration/*"/>
      <NotFound default/>
    </Router>
  );
};

export default GoogleAnalyticsRouter;
