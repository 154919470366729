import { useMessageSource } from 'react-message-source';

const AppointmentsPaging = ({disableNext, disablePrevious, currentPage, onNextClick, onPreviousClick}) => {
  const { getMessage } = useMessageSource('client.appointment.pagination');

  return (
    <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row xs:justify-between">
      <div className="inline-flex mt-2 xs:mt-0 items-center">
        <button
          onClick={onPreviousClick}
          disabled={disablePrevious}
          className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l disabled:opacity-50"
        >
          {getMessage('previous')}
        </button>
        <span className="text-xs xs:text-sm text-gray-900 mx-2">{getMessage('page')} {currentPage}</span>
        <button
          onClick={onNextClick}
          disabled={disableNext}
          className="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r disabled:opacity-50"
        >
          {getMessage('next')}
        </button>
      </div>
    </div>
  );
}

export default AppointmentsPaging;
