import classnames from 'classnames';

/**
 * Iterates the object for nested properties if the key contains a path using '.' as separator.
 *
 * @param dataRow the object containing the data
 * @param key the path to find in the object
 * @returns the value under the object.
 */
function getValueForKey(dataRow, key) {
  return key.split('.').reduce((o, i) => o[i], dataRow);
}

const Table = ({ columns, data, onRowClick }) => (
  <div className="flex flex-col mt-6">
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div className="overflow-hidden border-b border-gray-200 rounded-md shadow-md">
          <table className="min-w-full overflow-x-scroll divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <TableHeaderRow columns={columns} />
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data &&
                data.map((dataRow) => (
                  <TableRow
                    key={dataRow._id}
                    dataRow={dataRow}
                    columns={columns}
                    onClick={onRowClick}
                  />
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
);

const TableHeaderCell = ({ children, className }) => (
  <th
    scope="col"
    className={`px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ${className}`}
  >
    {children}
  </th>
);

const TableHeaderRow = ({ columns }) => (
  <tr>
    {columns.map((column, i) => (
      <TableHeaderCell key={i} className={column.className}>
        {column.title}
      </TableHeaderCell>
    ))}
  </tr>
);

const TableRowCell = ({ children, className }) => (
  <td className={`px-6 py-4 whitespace-nowrap ${className} `}>{children}</td>
);

const TableRow = ({ dataRow, columns, onClick }) => (
  <tr
    className={classnames('transition-all hover:bg-gray-100 hover:shadow-lg', {
      'cursor-pointer': onClick !== undefined,
    })}
    onClick={() => onClick && onClick(dataRow)}
  >
    {columns.map((column, i) => (
      <TableRowCell key={i} className={column.className}>
        {column.render ? column.render(dataRow) : getValueForKey(dataRow, column.key)}
      </TableRowCell>
    ))}
  </tr>
);

export default Table;
