import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useMessageSource } from 'react-message-source';

import { AuthenticationContextProvider } from './security/AuthenticationContext';
import ToastMessage from './components/ToastMessage';
import { useOuterClick } from './hooks/useOuterClick';
import GoogleAnalyticsRouter from './components/GoogleAnalyticsRouter';

const queryClient = new QueryClient();

function App() {
  const [errorMessage, setErrorMessage] = useState();
  const { getMessageWithNamedParams } = useMessageSource();

  const innerRef = useOuterClick(() => {
    setErrorMessage(undefined);
  });

  useEffect(() => {
    const globalErrorHandler = (error) => {
      // check for errorHandle config
      if (error.config.hasOwnProperty('globalErrorHandle') && !error.config.globalErrorHandle) {
        return Promise.reject(error);
      }

      // if has response show the error
      if (error.response) {
        const errorResponse = error.response.data;
        setErrorMessage(getMessageWithNamedParams(errorResponse.error, errorResponse.params));
      }

      return Promise.reject(error);
    };
    const interceptorId = axios.interceptors.response.use((response) => response, globalErrorHandler);

    return () => {
      axios.interceptors.response.eject(interceptorId);
    };
  }, [getMessageWithNamedParams]);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthenticationContextProvider>
        {errorMessage && (
          <ToastMessage
            ref={innerRef}
            text={errorMessage}
            show={!!errorMessage}
            onChange={() => setErrorMessage(undefined)}
          />
        )}
        <GoogleAnalyticsRouter />
      </AuthenticationContextProvider>
    </QueryClientProvider>
  );
}

export default App;
