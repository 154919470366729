import { UploadIcon } from './Icons';

const ImageUpload = ({ children, name, onChange }) => (
  <label className="cursor-pointer inline-flex items-center justify-center my-1 mr-1 px-4 py-1 space-x-1 bg-gray-200 rounded-md shadow hover:bg-opacity-20">
    <UploadIcon />
    <span className="ml-2">{children}</span>
    <input
      className="hidden"
      type="file"
      name={name}
      accept="image/*"
      onChange={(event) => onChange(event.target.files[0])}
    />
  </label>
);

export default ImageUpload;