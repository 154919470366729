import React from 'react';
import { useMessageSource } from 'react-message-source';
import classnames from 'classnames';
import { toLocalDateFormat, toLocalTimeFormat } from '../final-form/utils';
import { DeleteIcon, EditIcon } from '../Icons';
import LazyImage from '../LazyImage';

const PostContent = ({ post, editable, onEditClick, onDeleteClick }) => {
  return (
    <div className="bg-gray-200 p-2 rounded-xl my-4 mr-auto shadow-md relative">
      <p className="text-sm mb-1">
        <span className="font-bold">{post.client.email}</span> @ {toLocalDateFormat(post.createdAt)}{' '}
        {toLocalTimeFormat(post.createdAt)}
      </p>
      {post.image && <LazyImage src={`/uploads/clients/${post.image}`} alt="post_image" className="w-full" />}
      <p className="leading-tight font-semibold text-lg">
        {post.content}
      </p>
      <div className="flex p-1 justify-end">
        {editable && (
          <button
            onClick={() => onDeleteClick(post._id)}
            className="focus:outline-none cursor-pointer hover:opacity-50"
          >
            <DeleteIcon />
          </button>
        )}
        {editable && (
          <button onClick={onEditClick} className="focus:outline-none cursor-pointer hover:opacity-50">
            <EditIcon />
          </button>
        )}
      </div>
    </div>
  );
};

const CommentContent = ({ comment, editable, onDeleteClick, onEditClick }) => {
  const commentRef = React.useRef(null);

  React.useEffect(() => {
    if (window.location.hash === `#${comment._id}`) {
      setTimeout(() => commentRef.current.scrollIntoView({ behavior: 'smooth' }), 500);
    }
  }, [comment._id]);

  return (
    <div
      ref={commentRef}
      id={comment._id}
      className={classnames('bg-gray-200 ml-8 p-2 rounded-xl my-4 mr-auto shadow-md relative', {
        'selected-comment': window.location.hash === `#${comment._id}`,
      })}
    >
      <p className="text-sm mb-1">
        <span className="font-bold">{comment.createdBy.name}</span> @ {toLocalDateFormat(comment.createdAt)}{' '}
        {toLocalTimeFormat(comment.createdAt)}
      </p>
      <p className="leading-tight">{comment.content}</p>
      <div className="flex p-1 justify-end">
        {editable && (
          <button onClick={onDeleteClick} className="focus:outline-none cursor-pointer hover:opacity-50">
            <DeleteIcon />
          </button>
        )}
        {editable && (
          <button onClick={onEditClick} className="focus:outline-none cursor-pointer hover:opacity-50">
            <EditIcon />
          </button>
        )}
      </div>
    </div>
  );
};

const EditComment = ({ content, onApply, onCancel }) => {
  const [text, setText] = React.useState(content);
  const { getMessage } = useMessageSource('client.client.posts');
  return (
    <div className="bg-gray-200 ml-8 p-4 rounded-xl my-4 mr-auto shadow-md relative group w-full">
      <textarea className="w-full" rows={1} value={text} onChange={(event) => setText(event.target.value)} />
      <div className="flex justify-end">
        <button
          className="inline-flex items-center justify-center px-4 py-1 my-1 mr-1 space-x-1 bg-gray-200 rounded-md shadow hover:bg-opacity-20 disabled:opacity-50 mr-1"
          onClick={() => onApply(text)}
        >
          {getMessage('button.apply')}
        </button>
        <button
          className="inline-flex items-center justify-center px-4 py-1 my-1 mr-1 space-x-1 bg-gray-200 rounded-md shadow hover:bg-opacity-20 disabled:opacity-50"
          onClick={onCancel}
        >
          {getMessage('button.cancel')}
        </button>
      </div>
    </div>
  );
};

const Comment = ({ comment, editable, onUpdate, onDelete }) => {
  const [editing, setEditing] = React.useState(false);

  if (editing) {
    return (
      <EditComment
        content={comment.content}
        onApply={(text) => {
          onUpdate(comment.post, comment._id, text).then(() => setEditing(false));
        }}
        onCancel={() => setEditing(false)}
      />
    );
  } else {
    return (
      <CommentContent
        comment={comment}
        editable={editable}
        onDeleteClick={() => onDelete(comment.post, comment._id)}
        onEditClick={() => setEditing(true)}
      />
    );
  }
};

const AddCommentForm = ({ postId, onApply }) => {
  const [text, setText] = React.useState('');
  const { getMessage } = useMessageSource('client.client.posts');

  return (
    <div className="bg-gray-200 p-4 ml-8 rounded-xl my-4 mr-auto shadow-md relative group">
      <h3>{getMessage('title.addComment')}</h3>
      <textarea className="w-full" rows={1} value={text} onChange={(event) => setText(event.target.value)} />
      <div className="flex justify-end">
        <button
          disabled={!text}
          className="inline-flex items-center justify-center px-4 py-1 my-1 mr-1 space-x-1 bg-gray-200 rounded-md shadow hover:bg-opacity-20 disabled:opacity-50 mr-1"
          onClick={() => onApply(postId, text).then(() => setText(''))}
        >
          {getMessage('button.apply')}
        </button>
      </div>
    </div>
  );
};

const Post = ({
  post,
  onPostDelete,
  onPostUpdate,
  onCommentDelete,
  onCommentUpdate,
  onCommentAdded,
  editable = true,
  canAddComments = true,
  ...rest
}) => {
  return (
    <div className="flex flex-col sm:grid grid-cols-12 text-gray-600" {...rest}>
      <div className="hidden sm:block col-start-1 col-end-2 mr-10 sm:mx-auto relative">
        <div className="h-full w-6 flex items-center justify-center">
          <div className="h-full w-1 bg-gray-800 pointer-events-none" />
        </div>
        <div className="w-6 h-6 absolute top-14 -mt-3 rounded-full bg-gray-200 shadow" />
      </div>
      <div className="col-start-2 col-end-12">
        <PostContent post={post} onDeleteClick={onPostDelete} editable={editable} />
        {post.comments &&
          post.comments.map((comment) => (
            <Comment
              key={comment._id}
              comment={comment}
              editable={canAddComments}
              onDelete={onCommentDelete}
              onUpdate={onCommentUpdate}
            />
          ))}
        {canAddComments && <AddCommentForm postId={post._id} onApply={onCommentAdded} />}
      </div>
    </div>
  );
};

export default Post;
