import _set from 'lodash.set';

export const trackException = (error) => {
  console.error(error);
  if (process.env.NODE_ENV === 'production') {
    try {
      window.gtag('event', 'exception', {
        'description': error?.stack || error?.message || error,
        'fatal': false
      });
    } catch (err) {
      console.error('Unable to track error with GA', err);
    }
  }
};

export const checkForValidationErrors = (error, getMessage) => {
  const responseData = error.response.data;
  if (responseData.errorType === 'validation') {
    return responseData.params.reduce(
      (acc, current) => {
        const path = convertToLodashPath(current.path);
        return _set(acc, path, getMessage(current.error));
      },
      {}
    );
  } else {
    return {};
  }
};

const convertToLodashPath = (mongoosePath) => {
  return mongoosePath.split('.').reduce((acc, current) => {
    if (isNumeric(current)) {
      return `${acc}[${current}]`;
    }
    return `${acc}.${current}`;
  })
}

const isNumeric = (value) => {
  return /^\d+$/.test(value);
}