import React, { useEffect } from 'react';
import {
  addComment,
  deleteComment,
  getClientById,
  getClientPosts,
  updateComment,
  sendJournalEmail,
} from '../../../services/clientsService';
import Post from '../../../components/post/Post';
import { useMessageSource } from 'react-message-source';
import Modal from '../../../components/Modal';

const EmptyPosts = ({ id }) => {
  const [client, setClient] = React.useState({});
  const [sending, setSending] = React.useState(false);
  const [emailSent, setEmailSent] = React.useState(false);
  const [showSendConfirmation, setShowSendConfirmation] = React.useState(false);

  const { getMessage } = useMessageSource('client.client.posts.empty');

  useEffect(() => {
    getClientById(id).then((client) => {
      setClient(client);
    });
  }, [id]);

  const sendEmail = React.useCallback(() => {
    setSending(true);
    return sendJournalEmail(id)
      .then(() => {
        setEmailSent(true);
      })
      .finally(() => setSending(false));
  }, [id]);

  const onSendClick = React.useCallback(() => {
    if (client.journalEmailSent) {
      setShowSendConfirmation(true);
    } else {
      sendEmail();
    }
  }, [client, sendEmail]);

  return (
    <div className="flex flex-1 max-h-full">
      <div className="m-auto text-center">
        <h3>{getMessage('text')}</h3>
        {!emailSent && (
          <button
            className="inline-flex items-center justify-center px-4 py-1 my-1 mr-1 space-x-1 bg-gray-200 rounded-md shadow hover:bg-opacity-20 disabled:opacity-50 mr-1"
            disabled={sending}
            onClick={() => onSendClick()}
          >
            {getMessage('button.send')}
          </button>
        )}
        {emailSent && getMessage('send.success')}
        <Modal
          show={showSendConfirmation}
          title={getMessage('modal.title')}
          text={getMessage('modal.text')}
          acceptText={getMessage('modal.yes')}
          cancelText={getMessage('modal.no')}
          onAccept={() => sendEmail().finally(() => setShowSendConfirmation(false))}
          onCancel={() => {
            setShowSendConfirmation(false);
          }}
          disableAccept={sending}
        />
      </div>
    </div>
  );
};

const ClientPosts = ({ id }) => {
  const [posts, setPosts] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const { getMessage } = useMessageSource('client.client.posts');

  const fetchData = React.useCallback(() => {
    return getClientPosts(id).then((posts) => {
      setPosts(posts);
    });
  }, [id]);

  useEffect(() => {
    fetchData().finally(() => setLoading(false));
  }, [fetchData]);

  const onCommentAdded = React.useCallback(
    (postId, text) => {
      return addComment(id, postId, text).then(fetchData);
    },
    [id, fetchData]
  );

  const onCommentEdited = React.useCallback(
    (postId, commentId, text) => {
      return updateComment(id, postId, commentId, text).then(fetchData);
    },
    [id, fetchData]
  );

  const onCommentDeleted = React.useCallback((postId, commentId) => {
    return deleteComment(id, postId, commentId).then(fetchData);
  }, [id, fetchData]);

  if (loading) return 'Loading...';

  return (
    <>
      <h2 className="text-xl font-semibold">{getMessage('title')}</h2>
      {posts.length === 0 && <EmptyPosts id={id} />}
      {posts.map((post) => (
        <Post
          key={post._id}
          post={post}
          editable={false}
          canAddComments={true}
          onCommentAdded={onCommentAdded}
          onCommentUpdate={onCommentEdited}
          onCommentDelete={onCommentDeleted}
        />
      ))}
    </>
  );
};

export default ClientPosts;
