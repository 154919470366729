import React from 'react';
import meetingEndingSound from '../../assets/sound/meeting-ending.mp3';

const RoomDispatch = React.createContext(null);
const RoomState = React.createContext(null);

const initialState = {
  streaming: false,
  chatToggle: false,
  mirrorToggle: false,
  displayStream: false,
  serverTime: new Date(),
  messages: [],
  images: [],
  newMessage: false,
  videos: [],
  userDetails: undefined,
  error: undefined,
};

const roomReducer = (state, action) => {
  const audio = new Audio(meetingEndingSound);

  switch (action.type) {
    case 'error': {
      return {
        ...state,
        error: action.message,
      };
    }
    case 'serverTime': {
      const newServerTime = new Date(action.serverTime);
      if (state.userDetails) {
        const appointmentEndTime = state.userDetails.appointment?.end;
        const timeDifference = new Date(appointmentEndTime) - newServerTime;
        const minutes = Math.ceil(timeDifference / 1000 / 60);
        if (minutes === 10) {
          audio.play();
        }
      }
      return {
        ...state,
        serverTime: newServerTime,
      };
    }
    case 'userDetails': {
      return {
        ...state,
        userDetails: action.userDetails,
      };
    }
    case 'streaming': {
      return {
        ...state,
        streaming: action.streaming,
      };
    }
    case 'messages': {
      return {
        ...state,
        messages: action.messages,
        newMessage: true,
      };
    }
    case 'displayStream': {
      return {
        ...state,
        displayStream: action.displayStream,
      };
    }
    case 'addPeer': {
      return {
        ...state,
        videos: [...state.videos, action.peerVideo],
      };
    }
    case 'updatePeer': {
      return {
        ...state,
        videos: state.videos.map((video) => {
          if (video.id === action.peerVideo.id) {
            return action.peerVideo;
          }
          return video;
        }),
      };
    }
    case 'removePeer': {
      return {
        ...state,
        videos: state.videos.filter((video) => video.id !== action.id),
      };
    }
    case 'updateMirror': {
      return {
        ...state,
        mirrorToggle: action.mirrorToggle,
      };
    }
    case 'mirrorStatus': {
      return {
        ...state,
        videos: state.videos.map((video) => {
          if (video.socketId === action.socketId) {
            return {
              ...video,
              mirrorOn: action.mirrorOn,
            };
          }
          return video;
        }),
      };
    }
    case 'clearNewMessage': {
      return {
        ...state,
        newMessage: false,
      };
    }
    case 'addImage': {
      const imageDetails = action.imageDetails;
      const file = imageDetails.file;

      let src;
      if (file instanceof Blob) {
        src = window.URL.createObjectURL(file);
      } else {
        const blob = new Blob([file]);
        src = window.URL.createObjectURL(blob);
      }

      return {
        ...state,
        images: [...state.images, { src, fileName: imageDetails.fileName, userData: imageDetails.userData }],
        imagePreviewOpen: true
      };
    }
    case 'showImagePreview': {
      return {
        ...state,
        imagePreviewOpen: true
      }
    }
    case 'closeImagePreview': {
      return {
        ...state,
        imagePreviewOpen: false
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const RoomProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(roomReducer, initialState);

  return (
    <RoomDispatch.Provider value={dispatch}>
      <RoomState.Provider value={state}>{children}</RoomState.Provider>
    </RoomDispatch.Provider>
  );
};

function useRoomState() {
  const context = React.useContext(RoomState);
  if (context === undefined) {
    throw new Error('useCountState must be used within a RoomProvider');
  }
  return context;
}

function useRoomDispatch() {
  const context = React.useContext(RoomDispatch);
  if (context === undefined) {
    throw new Error('useCountDispatch must be used within a RoomProvider');
  }
  return context;
}

export { RoomProvider, useRoomState, useRoomDispatch };
