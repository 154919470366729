import { Link } from '@reach/router';
import { useMessageSource } from 'react-message-source';

import classnames from 'classnames';
import Table from '../../../components/Table';
import { useQuery } from 'react-query';
import { getUsers } from '../../../services/usersService';

const UsersOverview = () => {

  const { isLoading, error, data } = useQuery('users', () => getUsers());
  const { getMessage } = useMessageSource('client.user');

  if (isLoading) return getMessage('loading');

  if (error) return 'An error has occurred: ' + error.message;

  const columns = [
    {
      title: getMessage('details.name'),
      key: 'name',
      render: (user) => (
        <div className='flex items-center'>
          <div className='flex-shrink-0 w-10 h-10'>
            <img
              className='w-10 h-10 rounded-full'
              src='https://i.pravatar.cc/64?img=3'
              alt=''
            />
          </div>
          <div className='ml-4'>
            <div className='text-sm font-medium text-gray-900'>{user.name}</div>
            <div className='text-sm text-gray-500'>{user.email}</div>
          </div>
        </div>
      )
    },
    {
      title: getMessage('details.email'),
      key: 'email'
    },
    {
      title: getMessage('details.email.status'),
      render: (user) => (
        <span
          className={classnames('inline-flex px-2 text-xs font-semibold leading-5 rounded-full', {
            'text-green-800 bg-green-100': user.isEmailConfirmed,
            'text-red-800 bg-red-100': !user.isEmailConfirmed
          })}
        >
          {user.isEmailConfirmed ? getMessage('details.email.confirmed') : getMessage('details.email.notconfirmed')}
        </span>
      )
    },
    {
      title: getMessage('details.role'),
      render: (user) => (
        <div className='text-sm text-gray-900'>{getMessage(`details.role.${user.role}`)}</div>
      )
    },
    {
      title: getMessage('actions'),
      render: (user) => (
        <Link to={user._id} className='text-indigo-600 hover:text-indigo-900'>{getMessage('actions.edit')}</Link>
      )
    }
  ]

  return (
    <Table columns={columns} data={data} />
  );
}

export default UsersOverview;