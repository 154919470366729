import axios from 'axios';

const usersEndpoint = `${process.env.REACT_APP_API_CONTEXT_PATH}/users`;
const authEndpoint = `${process.env.REACT_APP_API_CONTEXT_PATH}/auth`;

export const getUsers = async (query = '') => {
  const usersResponse = await axios.get(`${usersEndpoint}?${query}`);
  return usersResponse.data.data;
};

export const getUserById = async (id) => {
  const userResponse = await axios.get(`${usersEndpoint}/${id}`);
  return userResponse.data.data;
};

export const createUser = async (data) => {
  const userResponse = await axios.post(usersEndpoint, data);
  return userResponse.data.data;
};

export const updateUser = async (id, data) => {
  const userResponse = await axios.put(`${usersEndpoint}/${id}`, data);
  return userResponse.data.data;
};

export const deleteUserById = async (id) => {
  const userResponse = await axios.delete(`${usersEndpoint}/${id}`);
  return userResponse.data;
};

export const registerUser = async (data) => {
  const userResponse = await axios.post(`${authEndpoint}/register`, data);
  return userResponse.data.data;
};

export const forgotPassword = async (email) => {
  const forgotPasswordResponse = await axios.post(`${authEndpoint}/forgot-password`, { email });
  return forgotPasswordResponse.data.data;
};

export const resetPassword = async (resetToken, data) => {
  const resetPasswordResponse = await axios.put(`${authEndpoint}/reset-password/${resetToken}`, data);
  return resetPasswordResponse.data.data;
};

export const updateDetails = async (data) => {
  const updateDetailsResponse = await axios.put(`${authEndpoint}/update-details`, data);
  return updateDetailsResponse.data.data;
}
