import { useCallback, useState } from 'react';
import classnames from 'classnames';
import { useMessageSource } from 'react-message-source';
import { toLocalDateFormat, toLocalTimeFormat } from '../../../components/final-form/utils';
import { DeleteIcon, EditIcon, StarEmptyIcon, StarIcon } from '../../../components/Icons';

export const Timeline = ({ notes, onFavouriteNoteSelected, onNoteEdited, onNoteDeleted }) => {
  return (
    <div className="flex flex-col sm:grid grid-cols-12 text-gray-600">
      {notes.map((note) => (
        <TimelineItem
          key={note._id}
          type={note.type}
          content={note.content}
          dateAdded={note.dateAdded}
          favourite={note.favourite}
          onFavouriteClick={() => onFavouriteNoteSelected(note._id, !note.favourite)}
          onNoteEdited={(text) => onNoteEdited(note._id, text)}
          onDeleteClick={() => onNoteDeleted(note._id)}
        />
      ))}
    </div>
  );
};

export const TimelineItemView = ({
  dateAdded,
  content,
  type,
  favourite,
  onFavouriteClick,
  onEditClick,
  onDeleteClick,
  viewOnly = false,
}) => (
  <div className="bg-gray-200 col-start-2 col-end-13 p-2 rounded-xl my-4 mr-auto shadow-md relative">
    <p className="text-sm mb-1">
      {toLocalDateFormat(dateAdded)} {toLocalTimeFormat(dateAdded)}
    </p>
    <p className="leading-tight text-justify font-semibold text-lg">
      {type === 'text' ? content : <img src={`/uploads/${content}`} alt="note" className="w-full" />}
    </p>
    <div className={classnames('flex p-1', { 'justify-between': !viewOnly, 'justify-end': viewOnly })}>
      {!viewOnly && (
        <button onClick={onDeleteClick} className="focus:outline-none cursor-pointer hover:opacity-50">
          <DeleteIcon />
        </button>
      )}
      {type === 'text' && !viewOnly && (
        <button onClick={onEditClick} className="focus:outline-none cursor-pointer hover:opacity-50">
          <EditIcon />
        </button>
      )}
      <button
        onClick={onFavouriteClick}
        className="focus:outline-none cursor-pointer hover:opacity-50"
      >
        {favourite ? <StarIcon /> : <StarEmptyIcon />}
      </button>
    </div>
  </div>
);

const TimelineItemEdit = ({ content, onApply, onCancel }) => {
  const [text, setText] = useState(content);
  const { getMessage } = useMessageSource('client.client.notes.timeline');
  return (
    <div className="bg-gray-200 col-start-2 col-end-6 p-4 rounded-xl my-4 mr-auto shadow-md relative group w-full">
      <textarea className="w-full" rows={3} value={text} onChange={(event) => setText(event.target.value)} />
      <div className="flex justify-end">
        <button
          className="inline-flex items-center justify-center px-4 py-1 my-1 mr-1 space-x-1 bg-gray-200 rounded-md shadow hover:bg-opacity-20 disabled:opacity-50 mr-1"
          onClick={() => onApply(text)}
        >
          {getMessage('button.apply')}
        </button>
        <button
          className="inline-flex items-center justify-center px-4 py-1 my-1 mr-1 space-x-1 bg-gray-200 rounded-md shadow hover:bg-opacity-20 disabled:opacity-50"
          onClick={onCancel}
        >
          {getMessage('button.cancel')}
        </button>
      </div>
    </div>
  );
};

const TimelineItem = ({ type, dateAdded, content, favourite, onFavouriteClick, onNoteEdited, onDeleteClick }) => {
  const [editing, setEditing] = useState(false);
  const handleEditClicked = useCallback(() => {
    setEditing(!editing);
  }, [editing]);

  const handleNoteEdited = (text) => {
    onNoteEdited(text).finally(() => setEditing(false));
  };

  return (
    <div className="flex sm:contents">
      <div className="hidden sm:block col-start-1 col-end-2 mr-10 sm:mx-auto relative">
        <div className="h-full w-6 flex items-center justify-center">
          <div className="h-full w-1 bg-gray-800 pointer-events-none" />
        </div>
        <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-gray-200 shadow" />
      </div>
      {editing ? (
        <TimelineItemEdit content={content} onCancel={() => setEditing(false)} onApply={handleNoteEdited} />
      ) : (
        <TimelineItemView
          type={type}
          dateAdded={dateAdded}
          content={content}
          favourite={favourite}
          onFavouriteClick={onFavouriteClick}
          onEditClick={handleEditClicked}
          onDeleteClick={onDeleteClick}
        />
      )}
    </div>
  );
};
