import classnames from 'classnames';
import { useState } from 'react';
import { Transition } from '@headlessui/react';
import { useMessageSource } from 'react-message-source';
import { Link } from '@reach/router';

import { useOuterClick } from '../../hooks/useOuterClick';
import { useAuthenticationContextValue } from '../../security/AuthenticationContext';

const Header = ({ sidebarOpen = false, toggleSidebarMenu }) => {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const { principal, logout } = useAuthenticationContextValue();
  const { getMessage } = useMessageSource();
  const innerRef = useOuterClick(() => setUserMenuOpen(false));
  return (
    <header className="flex-shrink-0 border-b">
      <div className="flex items-center justify-between p-2">
        <div className="flex items-center space-x-3">
          <span className="p-2 text-xl font-semibold tracking-wider uppercase lg:hidden">
            {getMessage('client.app.name')}
          </span>
          <button onClick={toggleSidebarMenu} className="p-2 rounded-md focus:outline-none focus:ring">
            <svg
              className={classnames('w-4 h-4 text-gray-600', {
                'transform transition-transform -rotate-180': sidebarOpen,
              })}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 5l7 7-7 7M5 5l7 7-7 7" />
            </svg>
          </button>
        </div>
        <div className="relative flex items-center space-x-3">
          <div className="relative" ref={innerRef}>
            <button
              onClick={() => setUserMenuOpen(!userMenuOpen)}
              className="p-1 bg-gray-200 rounded-full focus:outline-none focus:ring"
            >
              <img
                className="object-cover w-8 h-8 rounded-full"
                src="https://i.pravatar.cc/64?img=3"
                alt="Admin admin"
              />
            </button>
            <div className="absolute right-0 p-1 bg-green-400 rounded-full bottom-3 animate-ping" />
            <div className="absolute right-0 p-1 bg-green-400 border border-white rounded-full bottom-3" />

            <Transition show={userMenuOpen}>
              {(ref) => (
                <div
                  ref={ref}
                  className="absolute mt-3 transform -translate-x-full bg-white rounded-md shadow-lg min-w-max z-50"
                >
                  <div className="flex flex-col p-4 space-y-1 font-medium border-b">
                    <span className="text-gray-800">{principal.name}</span>
                    <span className="text-sm text-gray-400">{principal.email}</span>
                  </div>
                  <ul className="flex flex-col p-2 my-2 space-y-1">
                    <li>
                      <Link
                        to="/administration/update-details"
                        onClick={() => setUserMenuOpen(!userMenuOpen)}
                        className="block px-2 py-1 transition rounded-md hover:bg-gray-100"
                      >
                        {getMessage('client.user.update.details')}
                      </Link>
                    </li>
                  </ul>
                  <div className="flex items-center justify-center p-4 text-blue-700 underline border-t">
                    <button className="background-transparent text-xs outline-none focus:outline-none" onClick={logout}>
                      {getMessage('client.button.logout')}
                    </button>
                  </div>
                </div>
              )}
            </Transition>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
