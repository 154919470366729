import axios from 'axios';

const clientsEndpoint = `${process.env.REACT_APP_API_CONTEXT_PATH}/clients`;

export const getClients = async () => {
  const clientsResponse = await axios.get(clientsEndpoint);
  return clientsResponse.data.data;
}

export const getClientById = async (id) => {
  const clientResponse = await axios.get(`${clientsEndpoint}/${id}`);
  return clientResponse.data.data;
}

export const createClient = async (data) => {
  const clientResponse = await axios.post(clientsEndpoint, data);
  return clientResponse.data.data;
}

export const updateClient = async (id, data) => {
  const clientResponse = await axios.put(`${clientsEndpoint}/${id}`, data);
  return clientResponse.data.data;
}

export const deleteClientById = async (id) => {
  const clientResponse = await axios.delete(`${clientsEndpoint}/${id}`);
  return clientResponse.data;
}

export const addNote = async (id, data) => {
  const addNoteEndpoint = `${clientsEndpoint}/${id}/notes`;

  let clientResponse;
  if (data.type === 'text') {
    clientResponse = await axios.post(addNoteEndpoint, data);
  } else {
    const formData = new FormData();
    formData.append("file", data.file);
    clientResponse = await axios.post(addNoteEndpoint, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  return clientResponse.data.data;
}

export const updateNote = async (id, noteId, data) => {
  const clientResponse = await axios.put(`${clientsEndpoint}/${id}/notes/${noteId}`, data);

  return clientResponse.data.data;
}

export const deleteNote = async (id, noteId) => {
  const clientResponse = await axios.delete(`${clientsEndpoint}/${id}/notes/${noteId}`);
  return clientResponse.data.data;
}

export const getClientPosts = async (id) => {
  const clientPostsResponse = await axios.get(`${clientsEndpoint}/${id}/posts`);
  return clientPostsResponse.data.data;
}

export const addComment = async (id, postId, text) => {
  const addCommentResponse = await axios.post(`${clientsEndpoint}/${id}/posts/${postId}/comments`, { content: text });
  return addCommentResponse.data.data;
}

export const updateComment = async (id, postId, commentId, text) => {
  const updateCommentResponse = await axios.put(`${clientsEndpoint}/${id}/posts/${postId}/comments/${commentId}`, { content: text });
  return updateCommentResponse.data.data;
}

export const deleteComment = async (id, postId, commentId) => {
  const deleteCommentResponse = await axios.delete(`${clientsEndpoint}/${id}/posts/${postId}/comments/${commentId}`);
  return deleteCommentResponse.data.data;
}

export const sendJournalEmail = async (id) => {
  const sendJournalEmail = await axios.post(`${clientsEndpoint}/${id}/posts/notify`);
  return sendJournalEmail.data.data;
}