import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMessageSource } from 'react-message-source';
import { addNote, deleteNote, getClientById, updateNote } from '../../../services/clientsService';
import ImageUpload from '../../../components/ImageUpload';
import { AddIcon, StarIcon } from '../../../components/Icons';
import { Timeline, TimelineItemView } from './Timeline';
import Modal from '../../../components/Modal';

const ClientNotes = ({ id }) => {
  const [client, setClient] = useState({ notes: [] });
  const [newNoteText, setNewNoteText] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deletingId, setDeletingId] = useState('');
  const [deleting, setDeleting] = useState(false);
  const { getMessage } = useMessageSource('client.client.notes');
  const favouritesRef = useRef(null);

  useEffect(() => {
    getClientById(id).then((client) => {
      setClient(client);
    });
  }, [id]);

  const handleNewTextNote = useCallback(() => {
    if (newNoteText && newNoteText.length > 0) {
      const data = { type: 'text', content: newNoteText };
      addNote(id, data).then((client) => {
        setClient(client);
        setNewNoteText('');
      });
    }
  }, [id, newNoteText]);

  const handleImageUpload = useCallback(
    (file) => {
      const data = { type: 'image', file };
      addNote(id, data).then((client) => setClient(client));
    },
    [id]
  );

  const handleFavouriteNoteSelected = useCallback(
    (noteId, value) => {
      const data = { favourite: value };
      updateNote(id, noteId, data).then((client) => setClient(client));
    },
    [id]
  );

  const handleNoteEdited = useCallback(
    (noteId, text) => {
      const data = { content: text };
      return updateNote(id, noteId, data).then((client) => setClient(client));
    },
    [id]
  );

  const handleNoteDeleted = useCallback(
    (noteId) => {
      setDeletingId(noteId);
      setShowDeleteConfirmation(true);
    },
    []
  );

  const handleAcceptDelete = useCallback(() => {
    setDeleting(true);
    deleteNote(id, deletingId)
      .then((client) => setClient(client))
      .finally(() => {
        setDeleting(false);
        setShowDeleteConfirmation(false);
      });
  }, [id, deletingId]);

  return (
    <div>
      <h2 className="text-xl font-semibold">{getMessage('title')}</h2>
      <div className="flex flex-col sm:flex-row sm:divide-x-2">
        <div className="w-full sm:w-2/3 sm:pr-2">
          <div className="flex flex-col pt-1">
            <textarea rows={3} onChange={(event) => setNewNoteText(event.target.value)} value={newNoteText} />
            <div className="flex flex-col sm:flex-row justify-end">
              <ImageUpload name="file" onChange={handleImageUpload}>
                {getMessage('button.add.image')}
              </ImageUpload>
              <button
                disabled={newNoteText.length === 0}
                type="button"
                className="inline-flex items-center justify-center px-4 py-1 my-1 mr-1 space-x-1 bg-gray-200 rounded-md shadow hover:bg-opacity-20 disabled:opacity-50"
                onClick={handleNewTextNote}
              >
                <AddIcon />
                <span className="ml-2">{getMessage('button.add.text')}</span>
              </button>
              <button
                type="button"
                className="inline-flex sm:hidden items-center justify-center px-4 py-1 my-1 space-x-1 bg-gray-200 rounded-md shadow hover:bg-opacity-20 disabled:opacity-50"
                onClick={() => favouritesRef.current?.scrollIntoView({ behavior: 'smooth' })}
              >
                <StarIcon />
                <span className="ml-2">{getMessage('button.favourites')}</span>
              </button>
            </div>
          </div>
          <h3 className="text-lg text-center my-2">{getMessage('title.all')}</h3>
          <Timeline
            notes={client.notes}
            onFavouriteNoteSelected={handleFavouriteNoteSelected}
            onNoteEdited={handleNoteEdited}
            onNoteDeleted={handleNoteDeleted}
          />
        </div>
        <div className="w-full sm:w-1/3 sm:pl-2">
          <h3 ref={favouritesRef} className="text-lg text-center my-2">
            {getMessage('title.favourites')}
          </h3>
          {client.notes
            .filter((note) => note.favourite)
            .map((note) => (
              <TimelineItemView
                key={note._id}
                type={note.type}
                content={note.content}
                dateAdded={note.dateAdded}
                favourite={note.favourite}
                onFavouriteClick={() => handleFavouriteNoteSelected(note._id, !note.favourite)}
                viewOnly={true}
              />
            ))}
        </div>
      </div>
      <Modal
        show={showDeleteConfirmation}
        title={getMessage('modal.title')}
        text={getMessage('modal.text')}
        acceptText={getMessage('modal.yes')}
        cancelText={getMessage('modal.no')}
        onAccept={handleAcceptDelete}
        onCancel={() => {
          setShowDeleteConfirmation(false);
        }}
        disableAccept={deleting}
      />
    </div>
  );
};

export default ClientNotes;
