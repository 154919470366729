export const HomeIcon = () => (
  <span>
    <svg
      className="w-6 h-6 text-gray-400"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
      />
    </svg>
  </span>
);

export const UserIcon = () => (
  <span>
    <svg
      className="w-6 h-6 text-gray-400"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
      />
    </svg>
  </span>
);

export const TimeIcon = () => (
  <span>
    <svg
      className="w-6 h-6 text-gray-400"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  </span>
);

export const PatientIcon = () => (
  <span>
    <svg
      className="w-6 h-6 text-gray-400"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m9 10c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5zm0-9c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m6.5 21h-6c-.276 0-.5-.224-.5-.5v-4c0-2.481 2.019-4.5 4.5-4.5h9c1.227 0 2.408.505 3.243 1.386.19.2.182.517-.019.707-.2.191-.517.182-.707-.019-.646-.682-1.564-1.074-2.517-1.074h-9c-1.93 0-3.5 1.57-3.5 3.5v3.5h5.5c.276 0 .5.224.5.5s-.224.5-.5.5z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m14.5 24c-.189 0-.362-.106-.447-.276l-1.862-3.724h-2.691c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h3c.189 0 .362.107.447.276l1.518 3.036 2.575-6.009c.077-.179.25-.297.445-.303.193.007.375.102.462.276l2.862 5.724h2.691c.276 0 .5.224.5.5s-.224.5-.5.5h-3c-.189 0-.362-.107-.447-.276l-2.518-5.036-2.575 6.009c-.077.179-.25.297-.445.303-.005 0-.01 0-.015 0z"
      />
    </svg>
  </span>
);

export const CalendarIcon = () => (
  <span>
    <svg
      className="w-6 h-6 text-gray-400"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
      />
    </svg>
  </span>
);

export const AddUserIcon = () => (
  <span>
    <svg
      className="w-6 h-6 text-gray-400"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
      />
    </svg>
  </span>
);

export const AddIcon = () => (
  <span>
    <svg
      className="w-6 h-6 text-gray-400"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  </span>
);

export const RemoveIcon = () => (
  <span>
    <svg
      className="w-6 h-6 text-gray-400"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  </span>
);

export const EnterFullscreenIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3" />
  </svg>
);

export const ExitFullscreenIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3" />
  </svg>
);

export const AudioMutedIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <line x1="1" y1="1" x2="23" y2="23" />
    <path d="M9 9v3a3 3 0 0 0 5.12 2.12M15 9.34V4a3 3 0 0 0-5.94-.6" />
    <path d="M17 16.95A7 7 0 0 1 5 12v-2m14 0v2a7 7 0 0 1-.11 1.23" />
    <line x1="12" y1="19" x2="12" y2="23" />
    <line x1="8" y1="23" x2="16" y2="23" />
  </svg>
);

export const AudioOnIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z" />
    <path d="M19 10v2a7 7 0 0 1-14 0v-2" />
    <line x1="12" y1="19" x2="12" y2="23" />
    <line x1="8" y1="23" x2="16" y2="23" />
  </svg>
);

export const CloseVideoCallIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <line x1="18" y1="6" x2="6" y2="18" />
    <line x1="6" y1="6" x2="18" y2="18" />
  </svg>
);

export const VideoMutedIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M16 16v1a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h2m5.66 0H14a2 2 0 0 1 2 2v3.34l1 1L23 7v10" />
    <line x1="1" y1="1" x2="23" y2="23" />
  </svg>
);

export const VideoOnIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <polygon points="23 7 16 12 23 17 23 7" />
    <rect x="1" y="5" width="15" height="14" rx="2" ry="2" />
  </svg>
);

export const SettingsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <line x1="4" y1="21" x2="4" y2="14" />
    <line x1="4" y1="10" x2="4" y2="3" />
    <line x1="12" y1="21" x2="12" y2="12" />
    <line x1="12" y1="8" x2="12" y2="3" />
    <line x1="20" y1="21" x2="20" y2="16" />
    <line x1="20" y1="12" x2="20" y2="3" />
    <line x1="1" y1="14" x2="7" y2="14" />
    <line x1="9" y1="8" x2="15" y2="8" />
    <line x1="17" y1="16" x2="23" y2="16" />
  </svg>
);

export const ConnectionIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="-icon-placeholder"
  >
    <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2" />
  </svg>
);

export const FeatherIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z" />
  </svg>
);

export const PlayIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="-icon-placeholder"
  >
    <circle cx="12" cy="12" r="10" />
    <polygon points="10 8 16 12 10 16 10 8" />
  </svg>
);

export const CloseIcon = () => (
  <svg
    className="w-6 h-6 text-gray-600"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
  </svg>
);

export const PaperPlaneIcon = () => (
  <svg
    className="svg-inline--fa fa-paper-plane fa-w-16 w-12 h-12 py-2 mr-2"
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="paper-plane"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path
      fill="currentColor"
      d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"
    />
  </svg>
);

export const MirrorOnIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      fill="currentColor"
      d="m293.833 362.011c2.629 0 5.153-.458 7.5-1.29v83.779c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-227c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v122.011c0 4.136-3.365 7.5-7.5 7.5-4.136 0-7.5-3.364-7.5-7.5v-122.011c0-12.407 10.093-22.5 22.5-22.5h105.667c12.407 0 22.5 10.093 22.5 22.5v122.011c0 4.136-3.364 7.5-7.5 7.5-4.135 0-7.5-3.364-7.5-7.5-.001-.019-.001-122.011-.001-122.011 0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v227c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-83.78c2.348.833 4.871 1.29 7.501 1.29 12.407 0 22.5-10.093 22.5-22.5v-122.01c0-20.678-16.822-37.5-37.5-37.5h-105.667c-20.678 0-37.5 16.822-37.5 37.5v122.011c0 12.406 10.093 22.5 22.5 22.5z"
    />
    <path
      fill="currentColor"
      d="m376.666 332.011h-30c-4.142 0-7.5 3.358-7.5 7.5v104.989c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-97.489h15v97.489c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-104.989c0-4.142-3.358-7.5-7.5-7.5z"
    />
    <path
      fill="currentColor"
      d="m361.996 165c28.949 0 52.5-23.551 52.5-52.5s-23.552-52.5-52.5-52.5-52.5 23.551-52.5 52.5 23.551 52.5 52.5 52.5zm0-90c20.678 0 37.5 16.822 37.5 37.5s-16.822 37.5-37.5 37.5-37.5-16.822-37.5-37.5 16.822-37.5 37.5-37.5z"
    />
    <path
      fill="currentColor"
      d="m504.5 0h-285.667c-4.142 0-7.5 3.358-7.5 7.5v497c0 4.142 3.358 7.5 7.5 7.5h285.667c4.142 0 7.5-3.358 7.5-7.5v-497c0-4.142-3.358-7.5-7.5-7.5zm-33.106 30h-219.454l-15-15h249.454zm-230.061 10.606v200.394c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-196h210.667v422h-210.667v-196c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v200.394l-15 15v-460.787zm10.607 441.394h219.454l15 15h-249.454zm230.06-10.606v-430.788l15-15v460.787z"
    />
    <path
      fill="currentColor"
      d="m143.167 180h-105.667c-20.678 0-37.5 16.822-37.5 37.5v122.011c0 12.407 10.093 22.5 22.5 22.5 2.629 0 5.153-.458 7.5-1.29v124.863c0 14.565 11.851 26.416 26.417 26.416s26.417-11.851 26.417-26.417v-138.572h15v138.573c0 14.566 11.851 26.417 26.417 26.417s26.417-11.851 26.417-26.417v-124.864c2.348.833 4.871 1.29 7.5 1.29 12.407 0 22.5-10.093 22.5-22.5v-122.01c-.001-20.678-16.824-37.5-37.501-37.5zm22.5 159.511c0 4.136-3.364 7.5-7.5 7.5-4.135 0-7.5-3.364-7.5-7.5 0-.01 0-122.011 0-122.011 0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v268.083c0 6.295-5.122 11.417-11.417 11.417s-11.417-5.122-11.417-11.417v-146.072c0-4.142-3.358-7.5-7.5-7.5h-30c-4.142 0-7.5 3.358-7.5 7.5v146.073c0 6.295-5.122 11.417-11.417 11.417s-11.416-5.123-11.416-11.418v-268.083c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v122.011c0 4.136-3.364 7.5-7.5 7.5s-7.5-3.364-7.5-7.5v-122.011c0-12.407 10.093-22.5 22.5-22.5h105.667c12.407 0 22.5 10.093 22.5 22.5z"
    />
    <path
      fill="currentColor"
      d="m90.662 165c28.949 0 52.5-23.551 52.5-52.5s-23.551-52.5-52.5-52.5-52.5 23.551-52.5 52.5 23.551 52.5 52.5 52.5zm0-90c20.678 0 37.5 16.822 37.5 37.5s-16.822 37.5-37.5 37.5-37.5-16.822-37.5-37.5 16.822-37.5 37.5-37.5z"
    />
  </svg>
);

export const MirrorOffIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line strokeWidth="32" x1="1" y1="1" x2="511" y2="511" />
    <path
      fill="currentColor"
      d="m293.833 362.011c2.629 0 5.153-.458 7.5-1.29v83.779c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-227c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v122.011c0 4.136-3.365 7.5-7.5 7.5-4.136 0-7.5-3.364-7.5-7.5v-122.011c0-12.407 10.093-22.5 22.5-22.5h105.667c12.407 0 22.5 10.093 22.5 22.5v122.011c0 4.136-3.364 7.5-7.5 7.5-4.135 0-7.5-3.364-7.5-7.5-.001-.019-.001-122.011-.001-122.011 0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v227c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-83.78c2.348.833 4.871 1.29 7.501 1.29 12.407 0 22.5-10.093 22.5-22.5v-122.01c0-20.678-16.822-37.5-37.5-37.5h-105.667c-20.678 0-37.5 16.822-37.5 37.5v122.011c0 12.406 10.093 22.5 22.5 22.5z"
    />
    <path
      fill="currentColor"
      d="m376.666 332.011h-30c-4.142 0-7.5 3.358-7.5 7.5v104.989c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-97.489h15v97.489c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-104.989c0-4.142-3.358-7.5-7.5-7.5z"
    />
    <path
      fill="currentColor"
      d="m361.996 165c28.949 0 52.5-23.551 52.5-52.5s-23.552-52.5-52.5-52.5-52.5 23.551-52.5 52.5 23.551 52.5 52.5 52.5zm0-90c20.678 0 37.5 16.822 37.5 37.5s-16.822 37.5-37.5 37.5-37.5-16.822-37.5-37.5 16.822-37.5 37.5-37.5z"
    />
    <path
      fill="currentColor"
      d="m504.5 0h-285.667c-4.142 0-7.5 3.358-7.5 7.5v497c0 4.142 3.358 7.5 7.5 7.5h285.667c4.142 0 7.5-3.358 7.5-7.5v-497c0-4.142-3.358-7.5-7.5-7.5zm-33.106 30h-219.454l-15-15h249.454zm-230.061 10.606v200.394c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-196h210.667v422h-210.667v-196c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v200.394l-15 15v-460.787zm10.607 441.394h219.454l15 15h-249.454zm230.06-10.606v-430.788l15-15v460.787z"
    />
    <path
      fill="currentColor"
      d="m143.167 180h-105.667c-20.678 0-37.5 16.822-37.5 37.5v122.011c0 12.407 10.093 22.5 22.5 22.5 2.629 0 5.153-.458 7.5-1.29v124.863c0 14.565 11.851 26.416 26.417 26.416s26.417-11.851 26.417-26.417v-138.572h15v138.573c0 14.566 11.851 26.417 26.417 26.417s26.417-11.851 26.417-26.417v-124.864c2.348.833 4.871 1.29 7.5 1.29 12.407 0 22.5-10.093 22.5-22.5v-122.01c-.001-20.678-16.824-37.5-37.501-37.5zm22.5 159.511c0 4.136-3.364 7.5-7.5 7.5-4.135 0-7.5-3.364-7.5-7.5 0-.01 0-122.011 0-122.011 0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v268.083c0 6.295-5.122 11.417-11.417 11.417s-11.417-5.122-11.417-11.417v-146.072c0-4.142-3.358-7.5-7.5-7.5h-30c-4.142 0-7.5 3.358-7.5 7.5v146.073c0 6.295-5.122 11.417-11.417 11.417s-11.416-5.123-11.416-11.418v-268.083c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v122.011c0 4.136-3.364 7.5-7.5 7.5s-7.5-3.364-7.5-7.5v-122.011c0-12.407 10.093-22.5 22.5-22.5h105.667c12.407 0 22.5 10.093 22.5 22.5z"
    />
    <path
      fill="currentColor"
      d="m90.662 165c28.949 0 52.5-23.551 52.5-52.5s-23.551-52.5-52.5-52.5-52.5 23.551-52.5 52.5 23.551 52.5 52.5 52.5zm0-90c20.678 0 37.5 16.822 37.5 37.5s-16.822 37.5-37.5 37.5-37.5-16.822-37.5-37.5 16.822-37.5 37.5-37.5z"
    />
  </svg>
);

export const ChatIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 478 478"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      fill="currentColor"
      d="M426.667,0.002H51.2C22.923,0.002,0,22.925,0,51.202v273.067c0,28.277,22.923,51.2,51.2,51.2h60.587l-9.284,83.456
			c-1.035,9.369,5.721,17.802,15.09,18.837c4.838,0.534,9.674-1.023,13.292-4.279l108.919-98.014h186.863
			c28.277,0,51.2-22.923,51.2-51.2V51.202C477.867,22.925,454.944,0.002,426.667,0.002z M443.733,324.269
			c0,9.426-7.641,17.067-17.067,17.067H233.25c-4.217,0.001-8.284,1.564-11.418,4.386l-80.452,72.414l6.434-57.839
			c1.046-9.367-5.699-17.809-15.067-18.856c-0.63-0.07-1.263-0.106-1.897-0.105H51.2c-9.426,0-17.067-7.641-17.067-17.067V51.202
			c0-9.426,7.641-17.067,17.067-17.067h375.467c9.426,0,17.067,7.641,17.067,17.067V324.269z"
    />
  </svg>
);

export const SearchIcon = () => (
  <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
    <svg viewBox="0 0 24 24" className="h-4 w-4 fill-current text-gray-500">
      <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z" />
    </svg>
  </span>
);

export const AttachIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 512 512"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      fill="currentColor"
      d="m506.039062 180.492188c-7.773437-12.554688-21.523437-20.046876-36.78125-20.046876h-38.085937v-69.863281c0-16.863281-14.226563-30.578125-31.710937-30.578125h-186.441407c-.273437 0-.457031-.078125-.53125-.125l-33.367187-46.667968c-5.917969-8.273438-15.675782-13.210938-26.105469-13.210938h-121.304687c-17.488282 0-31.710938 13.71875-31.710938 30.578125v335.148437c0 17.257813 14.570312 31.300782 32.484375 31.300782h377.363281c5.878906 0 10.957032-3.394532 13.414063-8.320313l.011719.003907 84.5625-169.839844c6.132812-12.308594 5.457031-26.65625-1.796876-38.378906zm-474.328124-150.492188h121.304687c.898437 0 1.507813.394531 1.699219.664062l33.417968 46.734376c5.640626 7.890624 14.945313 12.605468 24.886719 12.605468h186.441407c1.046874 0 1.578124.492188 1.710937.667969v69.773437h-271.472656c-16.835938 0-32.148438 9.488282-39.011719 24.175782l-60.6875 129.871094v-283.824219c.132812-.175781.664062-.667969 1.710938-.667969zm449.269531 175.5-80.421875 161.523438h-361.992188l79.300782-169.699219c1.921874-4.113281 6.679687-6.878907 11.832031-6.878907h339.558593c4.792969 0 9.007813 2.183594 11.273438 5.839844 1.273438 2.058594 2.382812 5.328125.449219 9.214844zm0 0"
    />
  </svg>
);

export const UploadIcon = () => (
  <svg
    className="w-6 h-6 text-gray-600"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 340 340"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      fill="currentColor"
      d="M305,0H35C21.193,0,10,11.193,10,25v290c0,13.807,11.193,25,25,25h270c13.807,0,25-11.193,25-25V25
		C330,11.193,318.807,0,305,0z M300,260h-30l-75-150l-50,100l-25-50L70,260H40V30h260V260z"
    />
    <circle fill="currentColor" cx="100" cy="90" r="30" />
  </svg>
);

export const EditIcon = () => (
  <svg
    className="w-6 h-6 text-gray-500"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    fill="currentColor"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      stroke="currentColor"
      d="M496.063,62.299l-46.396-46.4c-21.2-21.199-55.69-21.198-76.888,0l-18.16,18.161l123.284,123.294l18.16-18.161
			C517.311,117.944,517.314,83.55,496.063,62.299z"
    />
    <path
      stroke="currentColor"
      d="M22.012,376.747L0.251,494.268c-0.899,4.857,0.649,9.846,4.142,13.339c3.497,3.497,8.487,5.042,13.338,4.143
			l117.512-21.763L22.012,376.747z"
    />
    <polygon stroke="currentColor" points="333.407,55.274 38.198,350.506 161.482,473.799 456.691,178.568" />
  </svg>
);

export const StarEmptyIcon = () => (
  <svg
    className="w-6 h-6 text-gray-500"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 49.94 49.94"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      stroke="currentColor"
      d="M48.856,22.731c0.983-0.958,1.33-2.364,0.906-3.671c-0.425-1.307-1.532-2.24-2.892-2.438l-12.092-1.757
	c-0.515-0.075-0.96-0.398-1.19-0.865L28.182,3.043c-0.607-1.231-1.839-1.996-3.212-1.996c-1.372,0-2.604,0.765-3.211,1.996
	L16.352,14c-0.23,0.467-0.676,0.79-1.191,0.865L3.069,16.623C1.71,16.82,0.603,17.753,0.178,19.06
	c-0.424,1.307-0.077,2.713,0.906,3.671l8.749,8.528c0.373,0.364,0.544,0.888,0.456,1.4L8.224,44.702
	c-0.232,1.353,0.313,2.694,1.424,3.502c1.11,0.809,2.555,0.914,3.772,0.273l10.814-5.686c0.461-0.242,1.011-0.242,1.472,0
	l10.815,5.686c0.528,0.278,1.1,0.415,1.669,0.415c0.739,0,1.475-0.231,2.103-0.688c1.111-0.808,1.656-2.149,1.424-3.502
	L39.651,32.66c-0.088-0.513,0.083-1.036,0.456-1.4L48.856,22.731z M37.681,32.998l2.065,12.042c0.104,0.606-0.131,1.185-0.629,1.547
	c-0.499,0.361-1.12,0.405-1.665,0.121l-10.815-5.687c-0.521-0.273-1.095-0.411-1.667-0.411s-1.145,0.138-1.667,0.412l-10.813,5.686
	c-0.547,0.284-1.168,0.24-1.666-0.121c-0.498-0.362-0.732-0.94-0.629-1.547l2.065-12.042c0.199-1.162-0.186-2.348-1.03-3.17
	L2.48,21.299c-0.441-0.43-0.591-1.036-0.4-1.621c0.19-0.586,0.667-0.988,1.276-1.077l12.091-1.757
	c1.167-0.169,2.176-0.901,2.697-1.959l5.407-10.957c0.272-0.552,0.803-0.881,1.418-0.881c0.616,0,1.146,0.329,1.419,0.881
	l5.407,10.957c0.521,1.058,1.529,1.79,2.696,1.959l12.092,1.757c0.609,0.089,1.086,0.491,1.276,1.077
	c0.19,0.585,0.041,1.191-0.4,1.621l-8.749,8.528C37.866,30.65,37.481,31.835,37.681,32.998z"
    />
  </svg>
);

export const StarIcon = () => (
  <svg
    className="w-6 h-6 text-gray-500"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19.481 19.481"
    stroke="currentColor"
    fill="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      stroke="currentColor"
      d="m10.201,.758l2.478,5.865 6.344,.545c0.44,0.038 0.619,0.587 0.285,0.876l-4.812,4.169 1.442,6.202c0.1,0.431-0.367,0.77-0.745,0.541l-5.452-3.288-5.452,3.288c-0.379,0.228-0.845-0.111-0.745-0.541l1.442-6.202-4.813-4.17c-0.334-0.289-0.156-0.838 0.285-0.876l6.344-.545 2.478-5.864c0.172-0.408 0.749-0.408 0.921,0z"
    />
  </svg>
);

export const DeleteIcon = () => (
  <svg
    className="w-6 h-6 text-gray-500"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 74 74"
    stroke="currentColor"
    fill="none"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      stroke="currentColor"
      d="m56.082 72h-38.164a3.079 3.079 0 0 1 -3.05-2.805l-4.36-52.061a1 1 0 0 1 1-1.083h50.992a1 1 0 0 1 1 1.083l-4.36 52.061a3.079 3.079 0 0 1 -3.058 2.805zm-43.49-53.949 4.27 50.977a1.066 1.066 0 0 0 1.056.972h38.164a1.066 1.066 0 0 0 1.057-.972l4.27-50.977z"
    />
    <path
      stroke="currentColor"
      d="m66.172 18.06h-58.344a2.17 2.17 0 0 1 -2.167-2.167v-5.041a2.169 2.169 0 0 1 2.167-2.167h58.344a2.169 2.169 0 0 1 2.167 2.167v5.042a2.17 2.17 0 0 1 -2.167 2.166zm-58.344-7.375a.167.167 0 0 0 -.167.167v5.042a.167.167 0 0 0 .167.167h58.344a.167.167 0 0 0 .167-.167v-5.042a.167.167 0 0 0 -.167-.167z"
    />
    <path
      stroke="currentColor"
      d="m45.812 10.685h-17.624a1 1 0 0 1 -1-1v-5.067a2.621 2.621 0 0 1 2.618-2.618h14.388a2.621 2.621 0 0 1 2.618 2.618v5.067a1 1 0 0 1 -1 1zm-16.624-2h15.624v-4.067a.618.618 0 0 0 -.618-.618h-14.388a.618.618 0 0 0 -.618.618z"
    />
    <path
      stroke="currentColor"
      d="m47.462 56.03c-.029 0-.059 0-.088 0a1 1 0 0 1 -.909-1.083l2.289-26.131a1 1 0 1 1 1.992.175l-2.288 26.127a1 1 0 0 1 -.996.912z"
    />
    <path stroke="currentColor" d="m37 56.03a1 1 0 0 1 -1-1v-26.13a1 1 0 1 1 2 0v26.13a1 1 0 0 1 -1 1z" />
    <path
      stroke="currentColor"
      d="m26.538 56.03a1 1 0 0 1 -1-.913l-2.284-26.13a1 1 0 1 1 1.992-.175l2.289 26.131a1 1 0 0 1 -.909 1.083c-.026.003-.059.004-.088.004z"
    />
  </svg>
);
