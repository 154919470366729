import ValidationMessage from './ValidationMessage';

const Input = ({ labelText, input, meta, ...rest}) => (
  (
    <label className='block'>
      <span className='text-gray-700'>{labelText}</span>
      <input className='mt-1 block w-full' {...input} {...rest}/>
      <ValidationMessage meta={meta} />
    </label>
  )
);

export default Input;
