import { useCallback, useEffect, useRef, useState } from 'react';
import { useMessageSource } from 'react-message-source';
import { SearchIcon } from '../../../components/Icons';

const AppointmentsFilter = ({ doctors, filters, onChange }) => {
  const { getMessage } = useMessageSource('client.appointment.search');
  const [filterState, setFilterState] = useState(filters || {});
  const containerRef = useRef();

  useEffect(() => {
    setFilterState(filters);
  }, [filters]);

  useEffect(() => {
    const containerRefCopy = containerRef.current;
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        onChange(filterState)
      }
    };
    containerRefCopy?.addEventListener("keydown", listener);
    return () => {
      containerRefCopy?.removeEventListener("keydown", listener);
    };
  }, [filterState, onChange])

  const handleFiltersChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      setFilterState({
        ...filterState,
        [name]: value,
      });
    },
    [filterState, setFilterState]
  );

  const updateFilters = useCallback(() => {
    onChange(filterState);
  }, [filterState, onChange]);

  const doctorOptions = doctors.map((doctor) => (
    <option key={doctor._id} value={doctor._id}>
      {doctor.name}
    </option>
  ));

  return (
    <div ref={containerRef} className="my-2 flex sm:flex-row flex-col">
      <div className="flex flex-row mb-1 sm:mb-0">
        <div className="relative">
          <select
            id="doctorFilterId"
            value={filterState.doctor || 'all'}
            name="doctor"
            onChange={handleFiltersChange}
            className="appearance-none h-full rounded-l border sm:border-r-0 block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-0 focus:bg-white focus:border-gray-500"
          >
            <option value="all">{getMessage('doctors.all')}</option>
            {doctorOptions}
          </select>
        </div>
        <div className="relative">
          <input
            id="dateFromFilterId"
            name="dateFrom"
            onChange={handleFiltersChange}
            value={filterState.dateFrom || ''}
            placeholder={getMessage('date.from')}
            className="appearance-none h-full border-t sm:rounded-r-none sm:border-r-0 border-r border-b block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="date"
          />
        </div>
        <div className="relative">
          <input
            id="dateToFilterId"
            name="dateTo"
            onChange={handleFiltersChange}
            value={filterState.dateTo || ''}
            placeholder={getMessage('date.to')}
            className="appearance-none h-full sm:rounded-r-none sm:border-r-0 border-r border-b block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="date"
          />
        </div>
      </div>
      <div className="block relative">
        <SearchIcon />
        <input
          id="searchFilterId"
          name="search"
          onChange={handleFiltersChange}
          value={filterState.search || ''}
          placeholder={getMessage('patients')}
          className="appearance-none h-full border border-gray-400 border-b sm:border-r-0 block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
        />
      </div>
      <div className="relative">
        <button
          onClick={updateFilters}
          className="appearance-none outline-none inline-flex items-center h-full justify-center border px-4 py-1 space-x-1 bg-gray-200 border-gray-400 focus:outline-none focus:border-gray-600 hover:bg-opacity-20"
          type="submit"
        >
          {getMessage('apply')}
        </button>
        <button
          onClick={() => onChange({ doctor: 'all' })}
          className="appearance-none outline-none inline-flex items-center h-full justify-center border rounded-r px-4 py-1 space-x-1 bg-red-500 border-gray-400 text-white focus:outline-none focus:border-gray-600 hover:bg-red-600"
        >
          {getMessage('reset')}
        </button>
      </div>
    </div>
  );
};

export default AppointmentsFilter;
