import ValidationMessage from './ValidationMessage';

const Select = ({ children, labelText, meta, input }) => (
  (
    <label className='block'>
      <span className='text-gray-700'>{labelText}</span>
      <select className='block w-full mt-1' {...input} >
        {children}
      </select>
      <ValidationMessage meta={meta} />
    </label>
  )
);

export default Select;