import classnames from 'classnames';
import { useAuthenticationContextValue } from '../../security/AuthenticationContext';
import { useMessageSource } from 'react-message-source';

const SidebarFooter = ({ sidebarOpen = false }) => {

  const { logout } = useAuthenticationContextValue();
  const { getMessage } = useMessageSource();

  return (
    <div className='flex-shrink-0 p-2 border-t max-h-14'>
      <button
        className='flex items-center justify-center w-full px-4 py-2 space-x-1 font-medium tracking-wider uppercase bg-gray-100 border rounded-md focus:outline-none focus:ring'
        onClick={logout}
      >
            <span>
              <svg
                className='w-6 h-6'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1'
                />
              </svg>
            </span>
        <span className={classnames({ 'lg:hidden': !sidebarOpen })}>{getMessage('client.button.logout')}</span>
      </button>
    </div>
  );
};

export default SidebarFooter;