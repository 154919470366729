import ValidationMessage from './ValidationMessage';

const Radio = ({labelText, meta, input}) => (
  (
    <label className='inline-flex items-center'>
      <input type='radio' {...input} />
      <span className='ml-2'>{labelText}</span>
      <ValidationMessage meta={meta} />
    </label>
  )
);

export default Radio;
