import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { useMessageSource } from 'react-message-source';

import { PaperPlaneIcon } from '../Icons';
import Drawer from '../layout/Drawer';
import { getMessageDateOrTime } from '../../utils/helper';
import FileUploader from './FileUploader';

const AlwaysScrollToBottom = () => {
  const elementRef = useRef();
  useEffect(() => elementRef.current?.scrollIntoView());
  return <div ref={elementRef} />;
};

const isEmptyOrSpaces = (str) => {
  return str === null || str.match(/^\s*$/) !== null;
}

const MessageWrapper = ({ myMessage, children }) => (
  <div className="clear-both">
    <div
      className={classnames('w-3/4 mx-4 my-2 p-2 rounded-lg', {
        'bg-gray-100': !myMessage,
        'bg-red-300 float-right': myMessage,
      })}
    >
      {children}
    </div>
  </div>
);

const ChatBox = (props) => {
  const [chatText, setChatText] = useState('');
  const { getMessage } = useMessageSource('client.chat');

  const handleChatText = (event) => {
    const { value } = event.target;
    setChatText(value);
  };

  const handleKeyUp = (event) => {
    if (event.which === 13) {
      handleSendText(event);
    }
  };

  const handleSendText = (event) => {
    if (isEmptyOrSpaces(chatText)) return;
    if (event.type === 'keyup' && event.which !== 13) {
      return;
    }
    const messageDetails = {
      message: {
        message: chatText,
        timestamp: new Date(),
      },
      userData: { ...props.myDetails },
    };
    props.socketInstance.broadcastMessage(messageDetails);
    setChatText('');
  };

  const preventEnterKey = (event) => {
    if (event.which === 13) {
      event.preventDefault();
    }
  };

  const handleFileUpload = (files) => {
    const file = files[0];
    const blob = new Blob(files, { type: file.type });

    props.socketInstance.broadcastImage({
      file: blob,
      fileName: file.name,
      fileType: file.type,
      userData: props.myDetails,
    });
  };

  return (
    <Drawer
      isOpen={props.chatToggle}
      title={getMessage('title')}
      closeDrawer={props.onChatClose}
      backgroundColor="bg-red-600"
    >
      <div className="flex h-full">
        <div className="flex-1 mt-20 mb-16 overflow-y-scroll">
          {props.messages?.map((chatDetails) => {
            const { userData, message } = chatDetails;
            return (
              <MessageWrapper myMessage={!userData.userID} key={message.timestamp}>
                <div className="inline">{message.message}</div>
                <span className="float-right text-gray-500 text-xs">
                  {userData?.name} at {getMessageDateOrTime(message.timestamp)}
                </span>
              </MessageWrapper>
            );
          })}
          <AlwaysScrollToBottom />
        </div>

        <div className="fixed w-full flex justify-between bg-red-600 bottom-0">
          <textarea
            className="flex-grow m-2 py-2 px-4 mr-1 border border-gray-300 bg-gray-200 resize-none outline-none"
            rows="1"
            placeholder={getMessage('message.placeholder')}
            onChange={handleChatText}
            onKeyUp={handleKeyUp}
            onKeyPress={preventEnterKey}
            value={chatText}
          />
          {/*<FileUploader onFileUpload={handleFileUpload} />*/}
          <button onClick={handleSendText} className="text-red-200 m-2 outline-none">
            <PaperPlaneIcon />
          </button>
        </div>
      </div>
    </Drawer>
  );
};

export default ChatBox;
