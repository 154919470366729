import classnames from 'classnames';
import { Transition } from '@headlessui/react';
import SidebarHeader from './SidebarHeader';
import Navigation from './Navigation';
import SidebarFooter from './SidebarFooter';
import Blur from './Blur';

const Sidebar = ({isOpen, toggleSidebarMenu}) => {

  const asideClasses = classnames('fixed inset-y-0 z-10 flex flex-col flex-shrink-0 w-64 max-h-screen overflow-hidden transition-all transform bg-white border-r shadow-lg lg:z-auto lg:static lg:shadow-none',
    {
      '-translate-x-full lg:translate-x-0 lg:w-20': !isOpen
    });
  return (
    <Transition show={isOpen}
                enter='transition transform duration-300'
                enter-start='-translate-x-full opacity-30  ease-in'
                enter-end='translate-x-0 opacity-100 ease-out'
                leave='transition transform duration-300'
                leave-start='translate-x-0 opacity-100 ease-out'
                leave-end='-translate-x-full opacity-0 ease-in'
    >
      {(ref) => (
        <>
          <Blur showBlur={isOpen}/>
          <aside className={asideClasses} ref={ref}>
            <SidebarHeader sidebarOpen={isOpen} toggleSidebarMenu={toggleSidebarMenu}  />
            <Navigation sidebarOpen={isOpen} />
            <SidebarFooter sidebarOpen={isOpen} />
          </aside>
        </>
        )}
    </Transition>

  );
};

export default Sidebar;