import React, { useCallback } from 'react';

const Placeholder = () => (
  <div className="w-full h-64 rounded bg-gray-400 animate-pulse" />
);

const LazyImage = ({ src, alt, ...rest }) => {
  const observerElement = React.useRef(null);
  const [visible, setVisible] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);

  const handleObserver = useCallback((entries) => {
    const [target] = entries;
    if (target.isIntersecting) {
      setVisible(true);
    }
  }, []);

  React.useEffect(() => {
    if (observerElement.current) {
      const element = observerElement.current;
      const option = { threshold: 0 };
      const observer = new IntersectionObserver(handleObserver, option);
      observer.observe(element);
      return () => observer.unobserve(element);
    }
  }, [handleObserver]);

  return <div ref={observerElement}>
    {!loaded && <Placeholder />}
    {visible && <img src={src} alt={alt} {...rest} onLoad={() => setLoaded(true)} />}
  </div>;
};

export default LazyImage;
