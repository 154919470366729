import { Redirect, Router } from '@reach/router';
import Users from '../../pages/administration/users/Users';
import Dashboard from '../../pages/administration/Dashboard';
import Appointments from '../../pages/administration/appointments/Appointments';
import PrivateRoute from '../../security/PrivateRoute';
import { useCurrentPrincipal } from '../../security/AuthenticationContext';
import UpdateUserDetails from '../../pages/administration/users/UpdateUserDetails';
import Clients from '../../pages/administration/clients/Clients';

const Main = () => {

  const principal = useCurrentPrincipal();

  return (
    <main className='flex flex-1 max-h-full p-5 overflow-hidden overflow-y-auto'>
      <Router className="flex flex-col flex-1 max-h-full">
        {principal.role !== 'admin' && <Redirect from="/" to="appointments/" noThrow/>}
        <PrivateRoute Component={Dashboard} rolesAllowed={['admin']} path='/'/>
        <PrivateRoute Component={Users} rolesAllowed={['admin']} path='/users/*'/>
        <Appointments path='/appointments/*'/>
        <Clients path='/clients/*'/>
        <UpdateUserDetails path='/update-details'/>
      </Router>
    </main>
  );
};

export default Main;