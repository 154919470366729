import { Link, Router } from '@reach/router';
import { useMessageSource } from 'react-message-source';

import PageHeader from '../../../components/PageHeader';
import AppointmentsOverview from './AppointmentsOverview';
import AppointmentDetails from './AppointmentDetails';
import { AddIcon } from '../../../components/Icons';

const Appointments = () => {
  const { getMessage } = useMessageSource('client.appointment');
  return (
    <>
      <PageHeader actions={[
        <Link key='new' to='new'
              className='inline-flex items-center justify-center px-4 py-1 space-x-1 bg-gray-200 rounded-md shadow hover:bg-opacity-20'>
          <AddIcon/>
          <span>{getMessage('new')}</span>
        </Link>
      ]}>{getMessage('title')}</PageHeader>
      <Router>
        <AppointmentsOverview path='/'/>
        <AppointmentDetails path='/:id'/>
      </Router>
    </>
  )
}

export default Appointments;