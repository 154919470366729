import openSocket from 'socket.io-client';
import Peer from 'peerjs';
import { getDisplayMedia, getUserMedia, removeBandwidthRestriction, setMediaBitrate } from '../utils/helper';
import { trackException } from '../utils/errors';
import { ICE_CONFIG, PEERJS_HOST, PEERJS_PORT, SIGNAL_SERVER_URL } from '../config';

let socketInstance = null;
let peers = {};

class SocketConnection {
  videoContainer = {};
  messages = [];
  settings;
  streaming = false;
  myPeer;
  socket;
  isSocketConnected = false;
  isPeersConnected = false;
  myID = '';
  bandwidth = false;

  constructor(settings) {
    this.settings = settings;
    this.socket = initializeSocketConnection();
    if (this.socket) this.isSocketConnected = true;
    this.initializeSocketEvents();
  }

  enablePeerConnections(userDetails, roomID) {
    this.myPeer = initializePeerConnection();
    if (this.myPeer) this.isPeersConnected = true;
    this.initializePeersEvents(userDetails, roomID);
  }

  initializeSocketEvents = () => {
    this.socket.on('connect', () => {
      this.socket.emit('user-token', { token: this.settings.params?.token });
    });
    this.socket.on('server-time', (serverTime) => {
      this.settings.roomDispatch({ type: 'serverTime', serverTime });
    });
    this.socket.on('user-details', (userDetails) => {
      this.settings.roomDispatch({ type: 'userDetails', userDetails });
    });
    this.socket.on('user-disconnected', (userID) => {
      peers[userID] && peers[userID].close();
      this.removeVideo(userID);
    });
    this.socket.on('error', (err) => {
      trackException(err);
    });
    this.socket.on('new-broadcast-messsage', (data) => {
      this.messages.push(data);
      this.settings.roomDispatch({ type: 'messages', messages: this.messages });
    });
    this.socket.on('display-media', (data) => {
      if (data.value) checkAndAddClass(this.getMyVideo(data.userID), 'displayMedia');
      else checkAndAddClass(this.getMyVideo(data.userID), 'userMedia');
    });
    this.socket.on('toggle-mirror', (toggleMirror) => {
      this.settings.roomDispatch({ type: 'updateMirror', mirrorToggle: toggleMirror });
    });
    this.socket.on('mirror-status-changed', (mirrorData) => {
      if (mirrorData.socketId !== this.socket.id) {
        this.settings.roomDispatch({ type: 'mirrorStatus', ...mirrorData });
      }
    });
    // this.socket.on('user-video-off', (data:UserVideoToggle) => {
    //     changeMediaView(data.id, data.status);
    // });
    this.socket.on('server-error', (err) => {
      this.settings.roomDispatch({ type: 'error', message: err.message });
    });
    this.socket.on('new-image-broadcast', (data) => {
      this.settings.roomDispatch({ type: 'addImage', imageDetails: data });
    });
  };

  initializePeersEvents = (userDetails, roomID) => {
    this.myPeer.on('open', (id) => {
      this.myID = id;
      const userData = {
        userID: id, roomID, ...userDetails
      };
      this.setNavigatorToStream(() => {
        this.socket.emit('join-room', userData);
      });
    });
    this.myPeer.on('error', err => {
      trackException(err);
      this.myPeer.reconnect();
    });
  };

  setNavigatorToStream = (callback) => {
    this.getVideoAudioStream().then(response => {
      if (response.stream) {
        const stream = response.stream;
        this.streaming = true;
        this.settings.roomDispatch({ type: 'streaming', streaming: true });
        this.createVideo({ id: this.myID, stream, socketId: this.socket.id });
        this.setPeersListeners(stream);
        this.newUserConnection(stream);
        if (callback) {
          callback();
        }
      } else {
        trackException(response.error);
        this.settings.roomDispatch({ type: 'error', message: response.error });
      }
    });
  };

  getVideoAudioStream = (video = true, audio = true) => {
    let quality = this.settings.params?.quality;
    if (quality) quality = parseInt(quality);
    const constraints = {
      video: video ? {
        frameRate: quality ? quality : 12
      } : false,
      audio: audio
    };
    // @ts-ignore
    return getUserMedia(constraints);
  };

  setPeersListeners = (stream) => {
    this.myPeer.on('call', (call) => {
      call.answer(stream);
      call.on('stream', (userVideoStream) => {
        this.createVideo({
          id: call.metadata.id,
          stream: userVideoStream,
          socketId: call.metadata.socketId
        });
      });
      call.on('close', () => {
        this.removeVideo(call.metadata.id);
      });
      call.on('error', (err) => {
        this.removeVideo(call.metadata.id);
        trackException(err);
      });
      peers[call.metadata.id] = call;
    });
  };

  newUserConnection = (stream) => {
    this.socket.on('new-user-connect', userData => {
      this.connectToNewUser(userData, stream);
    });
  };

  connectToNewUser(userData, stream) {
    const { userID } = userData;
    const call = this.myPeer.call(userID, stream, {
      sdpTransform: (sdp) => {
        let newSDP = sdp;
        if (this.bandwidth) {
          newSDP = setMediaBitrate(newSDP, 'video', 233);
          newSDP = setMediaBitrate(newSDP, 'audio', 80);
        } else {
          newSDP = removeBandwidthRestriction(sdp);
        }
        return newSDP;
      },
      metadata: { id: this.myID, socketId: this.socket.id }
    });
    call.on('stream', (userVideoStream) => {
      this.createVideo({ id: userID, stream: userVideoStream, ...userData });
    });
    call.on('close', () => {
      this.removeVideo(userID);
    });
    call.on('error', (err) => {
      this.removeVideo(userID);
      trackException(err);
    });
    peers[userID] = call;
  }

  broadcastMessage = (messageDetails) => {
    this.messages.push(messageDetails);
    this.settings.roomDispatch({ type: 'messages', messages: this.messages });
    this.socket.emit('broadcast-message', messageDetails);
  };

  broadcastImage = (imageDetails) => {
    this.settings.roomDispatch({ type: 'addImage', imageDetails });
    this.socket.emit('broadcast-image', imageDetails);
  }

  toggleMirror = (socketId, toggleMirror) => {
    this.socket.emit('toggle-mirror', { socketId, toggleMirror });
  };

  publishToggle = (mirrorToggle) => {
    this.socket.emit('mirror-status', { socketId: this.socket.id, mirrorOn: mirrorToggle });
  };

  createVideo = (createVideo) => {
    if (!this.videoContainer[createVideo.id]) {
      this.videoContainer[createVideo.id] = {
        ...createVideo,
        muted: this.myID === createVideo.id,
        mirrorOn: false
      };
      this.settings.roomDispatch({ type: 'addPeer', peerVideo: this.videoContainer[createVideo.id] });
    } else {
      this.videoContainer[createVideo.id].stream = createVideo.stream;
      this.settings.roomDispatch({ type: 'updatePeer', peerVideo: this.videoContainer[createVideo.id] });
    }
  };

  reInitializeStream = (video, audio, type = 'userMedia') => {
    // @ts-ignore
    const media = type === 'userMedia' ? this.getVideoAudioStream(video, audio) : getDisplayMedia();
    return new Promise((resolve) => {
      media.then((stream) => {
        // @ts-ignore
        const myVideo = this.getMyVideo();
        if (type === 'displayMedia') {
          this.toggleVideoTrack({ audio, video });
          this.listenToEndStream(stream, { video, audio });
          this.socket.emit('display-media', true);
        }
        checkAndAddClass(myVideo, type);
        this.createVideo({ id: this.myID, stream, socketId: this.socket.id });
        replaceStream(stream);
        resolve(true);
      });
    });
  };

  removeVideo = (id) => {
    delete this.videoContainer[id];
    this.settings.roomDispatch({ type: 'removePeer', id });
  };

  destoryConnection = () => {
    const myMediaTracks = this.videoContainer[this.myID]?.stream.getTracks();
    myMediaTracks?.forEach((track) => {
      track.stop();
    });
    socketInstance?.socket.disconnect();
    this.myPeer?.destroy();
  };

  getMyId = () => {
    return this.myID;
  }

  getMyVideo = (id = this.myID) => {
    return document.getElementById(id);
  };

  listenToEndStream = (stream, status) => {
    const videoTrack = stream.getVideoTracks();
    if (videoTrack[0]) {
      videoTrack[0].onended = () => {
        this.socket.emit('display-media', false);
        this.reInitializeStream(status.video, status.audio, 'userMedia');
        this.settings.roomDispatch({ type: 'displayStream', displayStream: false });
        this.toggleVideoTrack(status);
      };
    }
  };

  toggleVideoTrack = (status) => {
    const myVideo = this.getMyVideo();
    // @ts-ignore
    if (myVideo && !status.video) myVideo.srcObject?.getVideoTracks().forEach(track => {
      if (track.kind === 'video') {
        // track.enabled = status.video;
        // this.socket.emit('user-video-off', {id: this.myID, status: true});
        // changeMediaView(this.myID, true);
        !status.video && track.stop();
      }
    });
    else if (myVideo) {
      // this.socket.emit('user-video-off', {id: this.myID, status: false});
      // changeMediaView(this.myID, false);
      this.reInitializeStream(status.video, status.audio);
    }
  };

  toggleAudioTrack = (status) => {
    const myVideo = this.getMyVideo();
    // @ts-ignore
    if (myVideo) myVideo.srcObject?.getAudioTracks().forEach(track => {
      if (track.kind === 'audio')
        track.enabled = status.audio;
      status.audio ? this.reInitializeStream(status.video, status.audio) : track.stop();
    });
  };

}

const initializePeerConnection = () => {
  return new Peer(undefined, {
    host: PEERJS_HOST,
    port: PEERJS_PORT,
    secure: process.env.NODE_ENV === 'production',
    config: ICE_CONFIG
  });
};

const initializeSocketConnection = () => {
  return openSocket.connect(SIGNAL_SERVER_URL, {
    secure: process.env.NODE_ENV === 'production',
    reconnection: true,
    rejectUnauthorized: false,
    reconnectionAttempts: 10
  });
};

const replaceStream = (mediaStream) => {
  Object.values(peers).forEach((peer) => {
    peer.peerConnection?.getSenders().forEach((sender) => {
      if (sender.track.kind === 'audio') {
        if (mediaStream.getAudioTracks().length > 0) {
          sender.replaceTrack(mediaStream.getAudioTracks()[0]);
        }
      }
      if (sender.track.kind === 'video') {
        if (mediaStream.getVideoTracks().length > 0) {
          sender.replaceTrack(mediaStream.getVideoTracks()[0]);
        }
      }
    });
  });
};

const checkAndAddClass = (video, type = 'userMedia') => {
  if (video?.classList?.length === 0 && type === 'displayMedia')
    video.classList.add('display-media');
  else
    video.classList.remove('display-media');
};

export function createSocketConnectionInstance(settings = {}) {
  return socketInstance = new SocketConnection(settings);
}
