const PageHeader = ({children, actions}) => (
  (
    <div
      className='flex flex-col items-start justify-between pb-6 space-y-4 border-b lg:items-center lg:space-y-0 lg:flex-row'
    >
      <h1 className='text-2xl font-semibold whitespace-nowrap'>{children}</h1>
      <div className="space-x-2">
        {actions}
      </div>
    </div>
  )
);

export default PageHeader;
