import { useQuery } from 'react-query';
import { getClients } from '../../../services/clientsService';
import Table from '../../../components/Table';
import { Link, useNavigate } from '@reach/router';
import { useMessageSource } from 'react-message-source';

const ClientsOverview = () => {
  const { isLoading, error, data } = useQuery('clients', getClients);
  const { getMessage } = useMessageSource('client.client');
  const navigate = useNavigate();

  if (isLoading) return 'Loading...';

  if (error) return 'An error has occurred: ' + error.message;

  const columns = [
    {
      title: '',
      render: (client) => (
        <>
          <Link to={`${client._id}/notes`} onClick={event => event.stopPropagation()}>
            <button
              style={{ backgroundColor: '#2A9D8F' }}
              className="inline-flex items-center justify-center mr-1 px-4 py-1 space-x-1 text-white rounded-md shadow hover:bg-opacity-80 disabled:opacity-50"
            >
              {getMessage('actions.view.notes')}
            </button>
          </Link>
          <Link to={`${client._id}/posts`} onClick={event => event.stopPropagation()}>
            <button
              className="inline-flex bg-red-300 items-center justify-center mr-1 px-4 py-1 space-x-1 text-white rounded-md shadow hover:bg-opacity-80 disabled:opacity-50"
            >
              {getMessage('actions.view.posts')}
            </button>
          </Link>
        </>
      )
    },
    {
      title: getMessage('details.email'),
      key: 'email',
    },
    {
      title: getMessage('details.name'),
      key: 'name',
    },
    {
      title: getMessage('details.nickname'),
      key: 'nickname',
    },
    {
      title: getMessage('actions'),
      render: (client) => (
        <Link
          to={client._id}
          className="text-red-600 hover:text-red-900 ml-2"
          onClick={(event) => event.stopPropagation()}
        >
          {getMessage('actions.edit')}
        </Link>
      ),
    },
  ];

  return <Table columns={columns} data={data} onRowClick={(client) => navigate(`clients/${client._id}/notes`)} />;
};

export default ClientsOverview;
