import { Field, Form } from 'react-final-form';
import { useNavigate } from '@reach/router';
import { useMessageSource } from 'react-message-source';

import Input from '../../../components/final-form/Input';
import Radio from '../../../components/final-form/Radio';
import React, { useEffect, useState } from 'react';
import { registerUser, deleteUserById, getUserById, updateUser } from '../../../services/usersService';
import Modal from '../../../components/Modal';
import { checkForValidationErrors } from '../../../utils/errors';

const UserDetails = ({ id }) => {

  const [user, setUser] = useState({ role: 'doctor'});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const navigate = useNavigate();
  const { getMessage } = useMessageSource('client.user.details');

  useEffect(() => {
    if (id !== 'new') {
      getUserById(id).then(user => {
        setUser(user);
      });
    }
  }, [id]);

  const handleSubmit = async (values) => {
    const { _id, ...data } = values;

    try {
      if (_id) {
        const user = await updateUser(_id, data);
        setUser(user);
      } else {
        await registerUser(data);
        navigate('./')
      }
    } catch(error) {
      return checkForValidationErrors(error, getMessage);
    }
  };

  const handleDelete = () => {
    setShowDeleteConfirmation(true);
  };

  const handleAccept = () => {
    deleteUserById(id).then(() => {
      navigate('./');
    });
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={user}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            {
              id !== 'new' && (
                <Field
                  name='_id'
                  component={Input}
                  type='text'
                  placeholder={getMessage('id.placeholder')}
                  labelText={getMessage('id')}
                  readOnly
                />
              )
            }
            <Field
              name='name'
              component={Input}
              type='text'
              placeholder={getMessage('name.placeholder')}
              labelText={getMessage('name')}
            />
            <Field
              name='email'
              component={Input}
              type='text'
              placeholder={getMessage('email.placeholder')}
              labelText={getMessage('email')}
            />
            {id === 'new' && (
              <Field
                name='password'
                component={Input}
                type='password'
                placeholder={getMessage('password.placeholder')}
                labelText={getMessage('password')}
              />
            )
            }
            <div className='block'>
              <span className='text-gray-700'>{getMessage('role')}</span>
              <div className='mt-2'>
                <div>
                  <Field
                    name='role'
                    component={Radio}
                    labelText={getMessage('role.admin')}
                    type='radio'
                    value='admin'
                  />
                </div>
                <div>
                  <Field
                    name='role'
                    component={Radio}
                    labelText={getMessage('role.doctor')}
                    type='radio'
                    value='doctor'
                  />
                </div>
              </div>
            </div>
            <div className='flex pt-1 items-center'>
              <button
                disabled={submitting}
                className='inline-flex items-center justify-center mr-1 px-4 py-1 space-x-1 bg-gray-200 rounded-md shadow hover:bg-opacity-20 disabled:opacity-50'
                type='submit'
              >
                {getMessage('submit')}
              </button>
              {id !== 'new' && (
                <button
                  onClick={handleDelete}
                  className='inline-flex items-center justify-center px-4 py-1 space-x-1 bg-red-500 text-white rounded-md shadow hover:bg-red-600'
                  type='button'
                >
                  {getMessage('delete')}
                </button>
              )}
              { submitting && (
                <div className="animate-spin rounded-full border-t-2 border-blue-200 h-4 w-4 m-2"/>
              )}
            </div>
          </form>
        )}
      />
      <Modal
        show={showDeleteConfirmation}
        title={getMessage('modal.title')}
        text={getMessage('modal.text')}
        acceptText={getMessage('modal.yes')}
        cancelText={getMessage('modal.no')}
        onAccept={handleAccept}
        onCancel={() => {
          setShowDeleteConfirmation(false);
        }}
      />
    </>
  );
};

export default UserDetails;
