import { Link, Router } from '@reach/router';
import { useMessageSource } from 'react-message-source';

import PageHeader from '../../../components/PageHeader';
import UsersOverview from './UsersOverview';
import UserDetails from './UserDetails';
import { AddUserIcon } from '../../../components/Icons';

const Users = () => {

  const { getMessage } = useMessageSource('client.user');

  return (
    <>
      <PageHeader actions={[
        <Link key='new' to='new'
              className='inline-flex items-center justify-center px-4 py-1 space-x-1 bg-gray-200 rounded-md shadow hover:bg-opacity-20'>
          <AddUserIcon />
          <span>{getMessage('new')}</span>
        </Link>
      ]}>{getMessage('title')}</PageHeader>
      <Router>
        <UsersOverview path='/' />
        <UserDetails path='/:id' />
      </Router>
    </>
  );
};

export default Users;