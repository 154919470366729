import axios from 'axios';

const appointmentsEndpoint = `${process.env.REACT_APP_API_CONTEXT_PATH}/appointments`;

export const getAppointments = async (queryParams) => {
  const appointmentsResponse = await axios.get(appointmentsEndpoint, { params: queryParams });
  return appointmentsResponse.data;
};

export const getAppointmentById = async (id) => {
  const appointmentResponse = await axios.get(`${appointmentsEndpoint}/${id}`);
  return appointmentResponse.data.data;
};

export const createAppointment = async (data) => {
  const appointmentResponse = await axios.post(appointmentsEndpoint, data);
  return appointmentResponse.data.data;
};

export const updateAppointment = async (id, data) => {
  const appointmentResponse = await axios.put(`${appointmentsEndpoint}/${id}`, data);
  return appointmentResponse.data.data;
};

export const deleteAppointmentById = async (id) => {
  const appointmentResponse = await axios.delete(`${appointmentsEndpoint}/${id}`);
  return appointmentResponse.data;
};