import { trackException } from './errors';

export function getMessageDateOrTime(date = null) {
  if (date !== null) {
    const dateObj = new Date(date);
    const dateDetails = {
      date: dateObj.getDate(),
      month: dateObj.getMonth() + 1,
      year: dateObj.getFullYear(),
      hour: dateObj.getHours(),
      minutes: dateObj.getMinutes()
    };
    const currentDateObj = new Date();
    const currentDateDetails = {
      date: currentDateObj.getDate(),
      month: currentDateObj.getMonth() + 1,
      year: currentDateObj.getFullYear(),
      hour: currentDateObj.getHours(),
      minutes: currentDateObj.getMinutes()
    };
    if (dateDetails.year !== currentDateDetails.year && dateDetails.month !== currentDateDetails.month && dateDetails.date !== currentDateDetails.date) {
      return dateDetails.date + '-' + dateDetails.month + '-' + dateDetails.year;
    } else {
      return dateDetails.hour + ':' + dateDetails.minutes + ` ${dateDetails.hour < 12 ? 'AM' : 'PM'}`;
    }

  }
  return '';
}

export async function getDevices() {
  try {
    return navigator.mediaDevices.enumerateDevices();
  } catch (err) {
    trackException(err);
  }
  return [];
}

export let bandwidthVideoConstraints = {
  // video: {
  //   width: { ideal: 320 },
  //   height: { ideal: 240 },
  // },
  // width: { ideal: 320 },
  // height: { ideal: 240 },
};

export let defaultVideoConstraints = {
  width: { min: 640, ideal: 1280, max: 1920 },
  height: { min: 480, ideal: 720, max: 1080 },
  frameRate: {
    min: 1,
    ideal: 15
  },
  facingMode: 'user'
};

export let defaultAudioConstraints = {
  noiseSuppression: true
};

function __getUserMedia(constraints) {
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    return navigator.mediaDevices.getUserMedia(constraints);
  }
  const _getUserMedia =
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia;
  return new Promise((resolve, reject) => {
    if (!_getUserMedia) {
      reject(
        new Error(
          'Video and audio cannot be accessed. Please try again with another browser or check your browser\'s settings.'
        )
      );
    } else {
      _getUserMedia.call(navigator, constraints, resolve, reject);
    }
  });
}

export async function getUserMedia(constraints) {
  try {
    // Solution via https://stackoverflow.com/a/47958949/140927
    // Only available for HTTPS! See https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#Security
    let stream = await __getUserMedia({
      audio: {
        ...defaultAudioConstraints,
        ...constraints.audio
      },
      video: {
        ...defaultVideoConstraints,
        ...constraints.video
      }
    });
    return { stream };
  } catch (err) {
    const name = err?.name || err?.toString();
    if (name === 'NotAllowedError') {
      return {
        error: 'client.error.video.permissions'
      };
    } else if (name === 'NotFoundError') {
      return {
        error: 'client.error.video.notfound'
      };
    }
    trackException(err);
    return {
      error: err?.message || err?.name || err.toString()
    };
  }
}

// export async function getUserMedia(constraints = {
//   audio: {
//     ...defaultAudioConstraints,
//   },
//   video: {
//     ...defaultVideoConstraints,
//     facingMode: 'user',
//   },
// }) {
//   let audioStream = await _getUserMedia({ audio: constraints.audio, video: false })
//   let videoStream = await _getUserMedia({ video: constraints.video, audio: false })
//   if (audioStream?.stream && videoStream?.stream) {
//     videoStream.stream.addTrack(audioStream.stream.getAudioTracks()[0])
//   }
//   return videoStream || audioStream
// }

export async function getDisplayMedia(
  constraints = {
    video: {
      cursor: 'always'
    }
  }
) {
  try {
    if (!navigator?.mediaDevices?.getDisplayMedia) {
      return {
        error: 'Accessing the desktop is not available.'
      };
    }
    // Solution via https://stackoverflow.com/a/47958949/140927
    // Only available for HTTPS! See https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#Security
    let stream = await navigator.mediaDevices.getDisplayMedia(constraints);
    return { stream };
  } catch (err) {
    const name = err?.name || err?.toString();
    if (name === 'NotAllowedError') {
      return {
        error:
          'You denied access to your camera and microphone. Please check your setup.'
      };
    } else if (name === 'NotFoundError') {
      return {
        error: 'No camera or microphone has been found!'
      };
    }
    trackException(err);
    return {
      error: err?.message || err?.name || err.toString()
    };
  }
}

export function setMediaBitrate(sdp, media, bitrate) {
  let lines = sdp.split('\n');
  let line = -1;
  for (let i = 0; i < lines.length; i++) {
    if (lines[i].indexOf('m=' + media) === 0) {
      line = i;
      break;
    }
  }
  if (line === -1) {
    // log('Could not find the m line for', media)
    return sdp;
  }
  // log('Found the m line for', media, 'at line', line)

  // Pass the m line
  line++;

  // Skip i and c lines
  while (lines[line].indexOf('i=') === 0 || lines[line].indexOf('c=') === 0) {
    line++;
  }

  // If we're on a b line, replace it
  if (lines[line].indexOf('b') === 0) {
    // log('Replaced b line at line', line)
    lines[line] = 'b=AS:' + bitrate;
    return lines.join('\n');
  }

  // Add a new b line
  // log('Adding new b line before line', line)
  let newLines = lines.slice(0, line);
  newLines.push('b=AS:' + bitrate);
  newLines = newLines.concat(lines.slice(line, lines.length));
  return newLines.join('\n');
}

export function removeBandwidthRestriction(sdp) {
  return sdp.replace(/b=AS:.*\r\n/, '').replace(/b=TIAS:.*\r\n/, '');
}

export function trackPageView(path) {
  try {
    window.gtag('event', 'page_view', {
      page_path: path
    });
  } catch (err) {
    console.error('Unable to track page view with GA', err);
  }
}

export function trackEvent(action, category, label) {
  if (process.env.NODE_ENV === 'production') {
    window.gtag('event', action, {
      'event_category': category,
      'event_label': label
    });
  }
}
