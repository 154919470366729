import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from '@reach/router';
import { useMessageSource } from 'react-message-source';
import { format } from 'date-fns';

import { getAppointments } from '../../../services/appointmentsService';
import Table from '../../../components/Table';
import { useCurrentPrincipal } from '../../../security/AuthenticationContext';
import { getUsers } from '../../../services/usersService';
import AppointmentsFilter from './AppointmentsFilter';
import AppointmentsPaging from './AppointmentsPaging';

const now = new Date();

const prepareFilters = (filters) => {
  const dbFilterQuery = {};
  if (filters.doctor && filters.doctor !== 'all') {
    dbFilterQuery.doctor = filters.doctor;
  }
  if (filters.dateFrom) {
    // eslint-disable-next-line no-useless-computed-key
    dbFilterQuery['scheduledFor[gt]'] = filters.dateFrom;
  }
  if (filters.dateTo) {
    // eslint-disable-next-line no-useless-computed-key
    dbFilterQuery['scheduledFor[lt]'] = filters.dateTo;
  }
  if (filters.search) {
    dbFilterQuery['patients[regex]'] = filters.search;
    dbFilterQuery['patients[options]'] = 'i';
  }
  return dbFilterQuery;
};

const AppointmentsOverview = () => {
  const [filters, setFilters] = useState({});
  const [doctors, setDoctors] = useState([]);
  const [upcomingPage, setUpcomingPage] = useState(1);
  const [pastPage, setPastPage] = useState(1);
  const principal = useCurrentPrincipal();
  const { getMessage } = useMessageSource('client.appointment');

  const isAdmin = principal.role === 'admin';

  const { isLoading: isUpcomingLoading, data: upcomingData } = useQuery(
    ['appointmentsUpcoming', filters, upcomingPage],
    ({ queryKey }) => {
      const { 1: filters, 2: page } = queryKey;
      return getAppointments({
        ...prepareFilters(filters),
        ['scheduledFor[gt]']: now,
        sort: 'scheduledFor',
        page,
      });
    },
    { keepPreviousData: true, staleTime: 5000 }
  );
  const { isLoading: isPastLoading, data: pastData } = useQuery(
    ['appointmentsPast', filters, pastPage],
    ({ queryKey }) => {
      const { 1: filters, 2: page } = queryKey;
      return getAppointments({
        ...prepareFilters(filters),
        ['scheduledFor[lte]']: now,
        sort: '-scheduledFor',
        page,
      });
    },
    { keepPreviousData: true, staleTime: 5000 }
  );

  useEffect(() => {
    if (isAdmin) {
      getUsers('role=doctor').then((doctors) => {
        setDoctors(doctors);
      });
    }
  }, [principal, setDoctors, isAdmin]);

  const handleFilterChange = useCallback((updatedFilters) => {
    setFilters(updatedFilters);
  }, []);

  const handlePreviousUpcomingClick = useCallback(() => {
    setUpcomingPage(Math.max(upcomingPage - 1, 1));
  }, [upcomingPage]);

  const handleNextUpcomingClick = useCallback(() => {
    setUpcomingPage(upcomingData?.pagination.next ? upcomingPage + 1 : upcomingPage);
  }, [upcomingPage, upcomingData]);

  const handlePreviousPastClick = useCallback(() => {
    setPastPage(Math.max(pastPage - 1, 1));
  }, [pastPage]);

  const handleNextPastClick = useCallback(() => {
    setPastPage(pastData?.pagination.next ? pastPage + 1 : pastPage);
  }, [pastPage, pastData]);

  const columns = [
    {
      title: isAdmin ? getMessage('details.doctor') : getMessage('details.join'),
      key: 'doctor.name',
      className: isAdmin ? 'w-3/12' : 'w-1/12',
      render: (appointment) =>
        isAdmin ? (
          <span>{appointment.doctor.name}</span>
        ) : (
          appointment.doctorToken && (
            <Link to={`/room/${appointment._id}?token=${appointment.doctorToken}`}>
              <button
                style={{ backgroundColor: '#2A9D8F' }}
                className="inline-flex items-center justify-center mr-1 px-4 py-1 space-x-1 text-white rounded-md shadow hover:bg-opacity-80 disabled:opacity-50"
              >
                {getMessage('actions.join')}
              </button>
            </Link>
          )
        ),
    },
    {
      title: getMessage('details.patients'),
      key: 'patients',
      className: isAdmin ? 'w-4/12' : 'w-6/12',
      render: (appointment) => (
        <>
          {appointment.patients.map((patient) => (
            <span
              key={patient}
              className="inline-flex px-2 text-xs font-semibold leading-5 rounded-full text-blue-50 bg-blue-500 mr-1"
            >
              {patient}
            </span>
          ))}
        </>
      ),
    },
    {
      title: getMessage('details.scheduledfor'),
      key: 'scheduledFor',
      className: 'w-2/12',
      render: (appointment) => {
        if (!appointment.scheduledFor) return '';
        const dateTime = new Date(appointment.scheduledFor);
        return (
          <span>
            {format(dateTime, 'dd.MM.yyyy')} {format(dateTime, 'HH:mm')}
          </span>
        );
      },
    },
    {
      title: getMessage('details.duration'),
      key: 'durationInMinutes',
      className: 'w-1/12',
    },
    {
      title: getMessage('actions'),
      className: 'w-2/12',
      render: (appointment) => (
        <Link to={appointment._id} className="text-red-600 hover:text-red-900 ml-2">
          {getMessage('actions.edit')}
        </Link>
      ),
    },
  ];

  return (
    <>
      {isAdmin && <AppointmentsFilter doctors={doctors} filters={filters} onChange={handleFilterChange} />}
      <h1 className="my-2 text-l font-semibold whitespace-nowrap">{getMessage('upcoming')}</h1>
      {isUpcomingLoading ? getMessage('loading') : <Table columns={columns} data={upcomingData.data} />}
      {upcomingData && Object.keys(upcomingData.pagination).length > 0 && (
        <AppointmentsPaging
          currentPage={upcomingPage}
          disableNext={!upcomingData?.pagination.next}
          disablePrevious={upcomingPage === 1}
          onNextClick={handleNextUpcomingClick}
          onPreviousClick={handlePreviousUpcomingClick}
        />
      )}
      <h1 className="my-2 text-l font-semibold whitespace-nowrap">{getMessage('past')}</h1>
      {isPastLoading ? getMessage('loading') : <Table columns={columns} data={pastData.data} />}
      {pastData && Object.keys(pastData.pagination).length > 0 && (
        <AppointmentsPaging
          currentPage={pastPage}
          disableNext={!pastData?.pagination.next}
          disablePrevious={pastPage === 1}
          onNextClick={handleNextPastClick}
          onPreviousClick={handlePreviousPastClick}
        />
      )}
    </>
  );
};

export default AppointmentsOverview;
