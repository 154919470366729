import React from 'react';
import { useMessageSource } from 'react-message-source';
import { checkPasswordlessLoginStatus, getLoggedInClient, passwordlessLogin } from '../security/authenticationService';
import Posts from './Posts';
import logo from '../assets/images/logo.png';
import * as authenticationService from '../security/authenticationService';

const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

const PasswordlessLogin = () => {
  const { getMessage } = useMessageSource('client.passwordless');
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState('');
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [attemptedLogin, setAttemptedLogin] = React.useState(false);

  React.useEffect(() => {
    getLoggedInClient()
      .then(() => setLoggedIn(true))
      .catch(console.log);
  }, []);

  React.useEffect(() => {
    if (attemptedLogin) {
      const interval = setInterval(() => {
        checkPasswordlessLoginStatus(email).then(({ success }) => {
          if (success) {
            clearInterval(interval); // optional poll deactivation
            setLoggedIn(true);
            setAttemptedLogin(false);
          }
        });
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [email, attemptedLogin]);

  const handleSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      setError('');
      if (EMAIL_REGEX.test(email)) {
        passwordlessLogin(email)
          .then(() => setAttemptedLogin(true))
          .catch(console.log);
      } else {
        setError('server.error.validation.email');
      }
    },
    [email]
  );

  const handleLogout = async () => {
    return authenticationService.logout().then(() => {
      setLoggedIn(false);
      setAttemptedLogin(false);
    });
  };

  if (attemptedLogin) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 mx-auto">
        <div className="max-w-md w-full space-y-8">
          <div className='flex flex-col items-center'>
            <img className="mx-auto h-24 w-auto" src={logo} alt="logo" />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{getMessage('waiting')}</h2>
            <div className="inline-block animate-spin rounded-full border-t-2 border-blue-200 h-16 w-16 m-2"/>
          </div>
        </div>
      </div>
    );
  }

  if (!loggedIn) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 mx-auto">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img className="mx-auto h-24 w-auto" src={logo} alt="logo" />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{getMessage('prompt')}</h2>
          </div>
          <form className="mt-8 space-y-6" action="#" method="POST">
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  {getMessage('email')}
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm"
                  placeholder={getMessage('client.user.details.email.placeholder')}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              {error && <div className="text-red-500">{getMessage(error)}</div>}
            </div>
            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                onClick={handleSubmit}
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <svg
                    className="h-5 w-5 text-red-500 group-hover:text-red-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                {getMessage('login')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    return (
      <div className="max-h-full w-full overflow-y-scroll p-4">
        <Posts onLogout={handleLogout} />
      </div>
    );
  }
};

export default PasswordlessLogin;
