import classnames from 'classnames';
import { CalendarIcon, HomeIcon, PatientIcon, UserIcon } from '../Icons';
import { Link } from '@reach/router';
import { useMessageSource } from 'react-message-source';
import { useCurrentPrincipal } from '../../security/AuthenticationContext';

const Navigation = ({ sidebarOpen = false }) => {

  const principal = useCurrentPrincipal();
  const { getMessage } = useMessageSource('client.navigation');
  const dashboardText = getMessage('dashboard');
  const usersText = getMessage('users');
  const appointmentsText = getMessage('appointments');
  const clientsText = getMessage('clients');

  return (
    <nav className='flex-1 overflow-hidden hover:overflow-y-auto'>
      <ul className='p-2 overflow-hidden'>
        {
          principal.role === 'admin' && (
            <>
              <NavigationItem
                sidebarOpen={sidebarOpen}
                IconComponent={HomeIcon}
                text={dashboardText}
                path='/administration/'
              />
              <NavigationItem
              sidebarOpen={sidebarOpen}
              IconComponent={UserIcon}
              text={usersText}
              path='/administration/users'
              />
            </>
          )
        }
        <NavigationItem
          sidebarOpen={sidebarOpen}
          IconComponent={CalendarIcon}
          text={appointmentsText}
          path='/administration/appointments'
        />
        {
          principal.role === 'doctor' && (
            <NavigationItem
              sidebarOpen={sidebarOpen}
              IconComponent={PatientIcon}
              text={clientsText}
              path='/administration/clients'
            />
          )
        }
      </ul>
    </nav>
  );
};

const NavigationItem = ({ sidebarOpen, IconComponent, text, path }) => (
  (
    <li>
      <Link
        to={path}
        href='#'
        className={classnames('flex items-center p-2 space-x-2 rounded-md hover:bg-gray-100', {
          'justify-center': !sidebarOpen
        })}
      >
        <span>
          <IconComponent />
        </span>
        <span className={classnames({ 'lg:hidden': !sidebarOpen })}>{text}</span>
      </Link>
    </li>
  )
);


export default Navigation;