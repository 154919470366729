import { createContext, useContext, useEffect, useState } from 'react';
import * as authenticationService from './authenticationService';

const AuthenticationContextShape = {
  principal: undefined,
  login: async () => {
    return Promise.resolve();
  },
  logout: async () => {
    return Promise.resolve();
  }
};
const AuthenticationContext = createContext(AuthenticationContextShape);

const AuthenticationContextProvider = ({ children }) => {
  const [principal, setPrincipal] = useState();
  const [renderChildren, setRenderChildren] = useState(false);

  const getUserDetails = async () => {
    let loggedInUser = await authenticationService.getLoggedInUser();
    setPrincipal(loggedInUser.data);
    setRenderChildren(true);
    return Promise.resolve();
  }

  useEffect(() => {
    getUserDetails().catch(() => {
      setPrincipal(undefined);
      setRenderChildren(true);
    });
  }, []);

  const login = async (email, password) => {
    return authenticationService.login(email, password).then(() => {
      return getUserDetails();
    })
  };

  const logout = async () => {
    return authenticationService.logout().then(() => {
      setPrincipal(undefined)
    });
  };

  const contextValue = {
    principal,
    login,
    logout,
    getUserDetails
  };

  if (renderChildren) {
    return <AuthenticationContext.Provider value={contextValue}>{children}</AuthenticationContext.Provider>;
  }

  return (
    <div>Loading...</div>
  );
}

const useAuthenticationContextValue = () => {
  const authContext = useContext(AuthenticationContext);
  if (authContext === undefined) {
    throw new Error('useCurrentPrincipal must be used within a AuthenticationContextProvider');
  }
  return authContext;
}

const useCurrentPrincipal = () => {
  const authContext = useContext(AuthenticationContext);
  if (authContext === undefined) {
    throw new Error('useCurrentPrincipal must be used within a AuthenticationContextProvider');
  }
  return authContext.principal;
}

const useRefreshPrincipal = () => {
  const authContext = useContext(AuthenticationContext);
  if (authContext === undefined) {
    throw new Error('useRefreshPrincipal must be used within a AuthenticationContextProvider');
  }
  return authContext.getUserDetails;
}

export { useAuthenticationContextValue, useCurrentPrincipal, useRefreshPrincipal, AuthenticationContextProvider };
