import axios from 'axios';

const apiPath = process.env.REACT_APP_API_CONTEXT_PATH;

export const login = async (email, password) => {
  // this will add cookie with token if successful
  const response = await axios.post(`${apiPath}/auth/login`, {
    email,
    password
  });
  return response.data;
}

export const logout = async () => {
  const response = await axios.get(`${apiPath}/auth/logout`);
  return response.data;
}

export const getLoggedInUser = async () => {
  const response = await axios.get(`${apiPath}/auth/me`);
  return response.data;
}

export const getLoggedInClient = async () => {
  const clientInfoResponse = await axios.get(`${apiPath}/auth/client/me`)
  return clientInfoResponse.data.data;
}

export const passwordlessLogin = async (email) => {
  const passwordlessLoginResponse = await axios.post(`${apiPath}/auth/passwordless-login`, { email });
  return passwordlessLoginResponse.data.data;
}

export const checkPasswordlessLoginStatus = async (email) => {
  const checkLoginStatus = await axios.get(`${apiPath}/auth/passwordless-login-check/${email}`);
  return checkLoginStatus.data;
}