import axios from 'axios';

const postsEndpoint = `${process.env.REACT_APP_API_CONTEXT_PATH}/posts`;

export const getPosts = async (query = '') => {
  const clientResponse = await axios.get(`${postsEndpoint}?${query}`);
  return clientResponse.data;
};

export const createPost = async (data, onUploadProgressUpdate) => {
  const formData = new FormData();
  formData.append('file', data.file);
  formData.append('content', data.content);

  const clientResponse = await axios.post(postsEndpoint, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: data => {
      //Set the progress value to show the progress bar
      onUploadProgressUpdate(Math.round((100 * data.loaded) / data.total))
    },
  });
  return clientResponse.data.data;
};

export const deletePostById = async (id) => {
  const clientResponse = await axios.delete(`${postsEndpoint}/${id}`);
  return clientResponse.data;
};
