import { Transition } from '@headlessui/react'
import PropTypes from 'prop-types';

const Blur = ({ showBlur = true }) => (
  (
    <Transition
      show={showBlur}
      enter="transition ease-out duration-100 transform"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="transition ease-in duration-75 transform"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      {(ref) => (
        <div
          ref={ref}
          className="fixed inset-0 z-10 bg-black bg-opacity-20 lg:hidden"
          style={{
            'backdropFilter': 'blur(14px)',
            'WebkitBackdropFilter': 'blur(14px)'
          }}
        >
        </div>
      )}
    </Transition>
  )
);

Blur.propTypes = {
  showBlur: PropTypes.bool
}

export default Blur;