import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Sidebar from '../../components/sidebar/Sidebar';
import Header from '../../components/layout/Header';
import Main from '../../components/layout/Main';
import Footer from '../../components/layout/Footer';

const Administration = () => {

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const handleToggle = () => {
    setSidebarOpen(!sidebarOpen);
  }

  const isMobile = useMediaQuery({ query: `(max-width: 1023px)` });

  useEffect(() => {
    setSidebarOpen(!isMobile)
  }, [isMobile])

  return (
    <>
      <Sidebar isOpen={sidebarOpen} toggleSidebarMenu={handleToggle} />
      <div className="flex flex-col flex-1 h-full overflow-hidden">
        <Header sidebarOpen={sidebarOpen} toggleSidebarMenu={handleToggle}/>
        <Main/>
        <Footer />
      </div>
    </>
  );
}

export default Administration;
