import React, { useEffect, useRef, useState } from 'react';
import { parse } from 'query-string';
import { useLocation, useNavigate } from '@reach/router';
import classnames from 'classnames';
import { useMessageSource } from 'react-message-source';

import { createSocketConnectionInstance } from '../../services/socketConnection';
import { trackException } from '../../utils/errors';
import VideoPlayer from './VideoPlayer';
import ToolBar from './ToolBar';
import ChatBox from './ChatBox';
import { useRoomDispatch, useRoomState } from './RoomContext';
import logo from '../../assets/images/logo.png';
import ImagesPreview from './ImagesPreview';

const RoomComponent = ({ roomId }) => {
  const location = useLocation();
  const navigate = useNavigate();
  let socketInstance = useRef(null);
  const { getMessage } = useMessageSource('client.room');

  const [micStatus, setMicStatus] = useState(true);
  const [camStatus, setCamStatus] = useState(true);
  const [chatToggle, setChatToggle] = useState(false);

  const {
    streaming,
    mirrorToggle,
    userDetails,
    displayStream,
    serverTime,
    messages,
    images,
    imagePreviewOpen,
    newMessage,
    videos,
    error,
  } = useRoomState();

  const roomDispatch = useRoomDispatch();
  const isDoctor = userDetails && userDetails.role === 'doctor';

  useEffect(() => {
    let params = parse(location.search);
    if (!params) params = { quality: 12 };
    socketInstance.current = createSocketConnectionInstance({
      roomDispatch,
      params,
    });
    return () => {
      socketInstance.current?.destoryConnection();
    };
  }, [roomId, location.search, roomDispatch]);

  useEffect(() => {
    if (userDetails) {
      socketInstance.current?.enablePeerConnections(userDetails, roomId);
    }
  }, [userDetails, roomId]);

  useEffect(() => {
    if (streaming && isDoctor) {
      const { toggleMirror, getMyId } = socketInstance.current;
      videos
        .filter((video) => video.id !== getMyId())
        .forEach((video) => {
          toggleMirror(video.socketId, mirrorToggle);
        });
    }
  }, [mirrorToggle, isDoctor, streaming, videos]);

  useEffect(() => {
    roomDispatch({ type: 'clearNewMessage' });
  }, [chatToggle, roomDispatch]);

  useEffect(() => {
    const updateStream = (stream) => {
      try {
        if (stream) {
          stream?.getVideoTracks().forEach((t) => (t.enabled = camStatus));
          stream?.getAudioTracks().forEach((t) => (t.enabled = micStatus));
        }
      } catch (err) {
        trackException(err);
      }
    };

    if (socketInstance.current) {
      const { getMyVideo } = socketInstance.current;
      const video = getMyVideo();
      if (video && video.srcObject) {
        updateStream(video.srcObject);
      }
    }
  }, [micStatus, camStatus]);

  const handleDisconnect = () => {
    if (window.confirm(getMessage('close.prompt'))) {
      socketInstance.current?.destoryConnection();
      if (isDoctor) {
        navigate('/');
      } else {
        window.location = 'https://psihomedika.com';
      }
    }
  };

  const handleMyMic = () => {
    setMicStatus(!micStatus);
  };

  const handleMyCam = () => {
    if (!displayStream) {
      setCamStatus(!camStatus);
    }
  };

  const chatHandle = () => {
    setChatToggle(!chatToggle);
  };

  const handleMirrorToggle = () => {
    roomDispatch({ type: 'updateMirror', mirrorToggle: !mirrorToggle });
  };

  return (
    <React.Fragment>
      {error && (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-red-100 opacity-75 flex flex-col items-center justify-center">
          {getMessage(error)}
        </div>
      )}
      {!error && userDetails !== null && !streaming && (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
          <div className="animate-spin rounded-full border-t-4 border-blue-200 h-12 w-12 mb-4" />
        </div>
      )}
      <div id="room-container" className={classnames('relative', { fullscreen: mirrorToggle && !isDoctor })}>
        <span className="w-24 absolute opacity-75 z-20 top-1 left-1">
          <img src={logo} alt="logo" className="w-full" />
        </span>
        {videos.map((video) => {
          return (
            <VideoPlayer
              key={video.id}
              id={video.id}
              stream={video.stream}
              isSelf={video.muted}
              mirrorToggled={mirrorToggle}
              isDoctor={isDoctor}
            />
          );
        })}
      </div>
      <ToolBar
        audioOn={micStatus}
        videoOn={camStatus}
        onAudioClick={handleMyMic}
        onVideoClick={handleMyCam}
        onDisconnectClick={handleDisconnect}
        onChatClick={chatHandle}
        mirrorToggle={mirrorToggle}
        onMirrorToggleClick={handleMirrorToggle}
        serverTime={serverTime}
        newMessage={newMessage}
        onImagePreviewClick={() => roomDispatch({ type: 'showImagePreview' })}
      />
      <ChatBox
        chatToggle={chatToggle}
        onChatClose={() => chatHandle(false)}
        socketInstance={socketInstance.current}
        myDetails={userDetails}
        messages={messages}
      />
      <ImagesPreview
        images={images}
        isOpen={imagePreviewOpen}
        activePhotoIndex={Math.max(0, images.length - 1)}
        onClose={() => roomDispatch({ type: 'closeImagePreview' })}
      />
    </React.Fragment>
  );
};

export default RoomComponent;
