import React from 'react';
import classNames from 'classnames';

const VideoPlayer = ({ stream, id, isSelf, mirrorToggled, isDoctor}) => {

  const ref = React.useRef();

  React.useEffect(() => {
    if (stream) {
      if (ref.current) {
        const video = ref.current;
        if ('srcObject' in video) {
          video.srcObject = stream;
        } else {
          video.src = window.URL.createObjectURL(stream); // for older browsers
        }
      }
    }
  }, [stream]);

  const cssClasses = classNames({
    'fullscreen': isSelf && mirrorToggled,
    'hidden': isSelf && !mirrorToggled && !isDoctor,
    'absolute bottom-1 left-1 w-1/6 h-1/6 z-20 border border-gray-500': isSelf && isDoctor,
    'w-full h-full': !isSelf,
  });

  return (
    <div className={cssClasses}>
      <video ref={ref} autoPlay id={id} muted={isSelf} playsInline={true}/>
    </div>
  );
};

export default VideoPlayer;