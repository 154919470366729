import { useMessageSource } from 'react-message-source';

import Statistic from '../../components/Statistic';
import PageHeader from '../../components/PageHeader';

const Dashboard = () => {
  const { getMessage } = useMessageSource('client.dashboard')
  return (
    <>
      <PageHeader>{getMessage('title')}</PageHeader>

      <div className='grid grid-cols-1 gap-5 mt-6 sm:grid-cols-2 lg:grid-cols-4'>
        {
          Array.from(Array(4).keys()).map(i => (
            <Statistic key={i} />
          ))
        }
      </div>
    </>
  )
}

export default Dashboard;
