import { useMessageSource } from 'react-message-source';
import { Field, Form } from 'react-final-form';
import Input from '../../../components/final-form/Input';
import { updateDetails } from '../../../services/usersService';
import { checkForValidationErrors } from '../../../utils/errors';
import { useCurrentPrincipal, useRefreshPrincipal } from '../../../security/AuthenticationContext';

const UpdateUserDetails = () => {
  const principal = useCurrentPrincipal();
  const refreshPrincipal = useRefreshPrincipal();
  const { getMessage } = useMessageSource('client.user.details');

  const handleSubmit = async (values) => {
    try {
      await updateDetails(values);
      refreshPrincipal();
    } catch (error) {
      return checkForValidationErrors(error, getMessage);
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{ name: principal.name, email: principal.email }}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="name"
            component={Input}
            type="text"
            placeholder={getMessage('name.placeholder')}
            labelText={getMessage('name')}
          />
          <Field
            name="email"
            component={Input}
            type="text"
            placeholder={getMessage('email.placeholder')}
            labelText={getMessage('email')}
          />
          <div className="flex pt-1 items-center">
            <button
              disabled={submitting}
              className="inline-flex items-center justify-center mr-1 px-4 py-1 space-x-1 bg-gray-200 rounded-md shadow hover:bg-opacity-20 disabled:opacity-50"
              type="submit"
            >
              {getMessage('submit')}
            </button>
            {submitting && <div className="animate-spin rounded-full border-t-2 border-blue-200 h-4 w-4 m-2" />}
          </div>
        </form>
      )}
    />
  );
};

export default UpdateUserDetails;
