import { Link, Router } from '@reach/router';
import { useMessageSource } from 'react-message-source';

import PageHeader from '../../../components/PageHeader';
import ClientsOverview from './ClientsOverview';
import ClientDetails from './ClientDetails';
import ClientNotes from './ClientNotes';
import ClientPosts from './ClientPosts';
import { AddIcon } from '../../../components/Icons';

const Clients = () => {
  const { getMessage } = useMessageSource('client.client');
  return (
    <>
      <PageHeader
        actions={[
          <Link
            key="new"
            to="new"
            className="inline-flex items-center justify-center px-4 py-1 space-x-1 bg-gray-200 rounded-md shadow hover:bg-opacity-20"
          >
            <AddIcon />
            <span>{getMessage('new')}</span>
          </Link>,
        ]}
      >
        {getMessage('title')}
      </PageHeader>
      <Router className="flex flex-1 flex-col max-h-full">
        <ClientsOverview path="/" />
        <ClientDetails path="/:id" />
        <ClientNotes path="/:id/notes" />
        <ClientPosts path="/:id/posts" />
      </Router>
    </>
  );
};

export default Clients;
