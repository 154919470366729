import classnames from 'classnames';
import { CloseIcon } from '../Icons';

const Drawer = ({ isOpen, closeDrawer, title, children, backgroundColor }) => {
  return (
    <aside
      className={classnames(
        'transform top-0 right-0 md:w-1/4 w-full bg-white fixed h-full overflow-auto ease-in-out transition-all duration-300 z-50',
        {
          'translate-x-0': isOpen,
          'translate-x-full': !isOpen
        }
      )}
    >
      <div
        className={`fixed flex w-full justify-between p-2 shadow-md overscroll-none top-0 ${backgroundColor}`}
      >
        <button className="outline-none float-left" onClick={closeDrawer}><CloseIcon/></button>
        <div className="my-3 text-white font-bold text-lg tracking-wide">{title}</div>
      </div>
      {children}
    </aside>
  );
};

export default Drawer;